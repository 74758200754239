<template>
  <g>
    <template v-if="type === 'side' || type ==='edge'">
      <line :x1="(type === 'edge') ? -20 : 20" :y1="(type === 'edge') ? -120 : -190" :x2="(type === 'edge') ? length + 20 : length - 40" :y2="(type === 'edge') ? -120 : -190" stroke="black" />
    </template>
    
    <template v-if="type === 'edge'">
      <!-- |/ edge -->
      <line :x1="-13" :y1="12 + ((type === 'edge') ? -120 : -190)" :x2="13" :y2="-14 + ((type === 'edge') ? -120 : -190)" stroke="black" />
      <line :x1="0" :y1="30 + ((type === 'edge') ? -120 : -190)" :x2="0" :y2="-14 + ((type === 'edge') ? -120 : -190)" stroke="black" />
      
      <template v-if="isLastSideElement">
        <!-- /| edge -->
        <line :x1="-13 + length" :y1="12 + ((type === 'edge') ? -120 : -190)" :x2="13 + length" :y2="-14 + ((type === 'edge') ? -120 : -190)" stroke="black" />
        <line :x1="length" :y1="30 + ((type === 'edge') ? -120 : -190)" :x2="length" :y2="-14 + ((type === 'edge') ? -120 : -190)" stroke="black" />
      </template>
    </template>

    <g v-if="isFirstSideElement && hasSideLabel">
      <!-- |/ side -->
      <line :x1="-13" :y1="12 -190" :x2="13" :y2="-14 + ((type === 'edge') ? -190 : -170)" stroke="black" />
      <line :x1="0" :y1="-14 - 120" :x2="0" :y2="-14 - 190" stroke="black" />
      <!-- -- side -->
      <line x1="-20" :y1="-190" :x2="200" :y2="-190" stroke="black" />
    </g>

    <template v-if="isLastSideElement && hasSideLabel">
      <!-- /| side -->
      <line :x1="-13 + length" :y1="12 -190" :x2="13 + length" :y2="-14 + ((type === 'edge') ? -190 : -170)" stroke="black" />
      <line :x1="length" :y1="-14 - 120" :x2="length" :y2="-14 - 190" stroke="black" />
      <!-- -- side -->
      <line :x1="length-200" :y1="-190" :x2="length+20" :y2="-190" stroke="black" />
    </template>


    <text :x="length/2" :y="(type === 'edge') ? -100 : -170" text-anchor="middle" font-size="60px" style="stroke:white; stroke-width:0.6em">
      <template v-if="type === 'interrupt'">
        Unterbrechung
      </template>
      {{ lengthMm }}mm
      <template v-if="originalLengthMm != lengthMm && originalLengthMm > 0">
        *
      </template>
    </text>
    <text :x="length/2" :y="(type === 'edge') ? -100 : -170" text-anchor="middle" font-size="60px">
      <template v-if="type === 'interrupt'">
        Unterbrechung
      </template>
      {{ lengthMm }}mm
      <template v-if="originalLengthMm != lengthMm && originalLengthMm > 0">
        *
      </template>
    </text>
  </g>
</template>

<script>
export default {
  name: 'Label',
  props: {
    length: { // the length to display
      type: Number,
      default: 0
    },
    lengthMm: {
      type: Number,
      default: 0,
    },
    originalLengthMm: {
      type: Number,
      default: 0,
    },
    type: String, // 'edge', 'side', 'interrupt'
    isFirstSideElement: {
      type: Boolean,
      default: false,
    },
    isLastSideElement: {
      type: Boolean,
      default: false,
    },
    hasSideLabel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
  }

}
</script>

<style scoped>
</style>
