<template>
  <v-card class="mr-2 mt-2" v-if="articleAmountList.length > 0 || partList && partList.length > 0">
    <v-tabs
      v-model="tab"
      grow
    >
      <v-tab :key="1" >
      Ihre gewählten Produkte
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item :key="1">
        <v-row>
          <v-list width="100%" class="pl-6 pr-6">
            <v-list-item
              v-if="partList && partList.length > 0"
              :key="'configured-product'"
            >
              <v-list-item-content>
                <v-list-item-title @click="navigateToConfigurator" style="cursor:pointer">1x Ihre Auswahl im Konfigurator</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-btn
                  icon
                  @click="resetConfiguration"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item
              v-for="(article, i) in articleAmountList"
              :key="i" small
            >
              <v-list-item-content v-if="article">
                <v-list-item-title @click="navigateToAdditionalProducts" style="cursor:pointer">{{ article.amount }}x {{ article.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-btn
                  icon
                  @click="article.removeHandler"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-row>
        <v-row v-if="guideTab != 2">
          <v-col>
            <v-btn
              color="primary"
              style="float: right"
              class="mr-3 mb-3"
              @click="navigateToCheckout"
            >
              Senden / Drucken
            </v-btn>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>


<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'AdditionalProducts',
  components: {
  },
  props: {
  },
  computed: {
    ...mapGetters({
      partList: 'partListForSides',
    }),
    ...mapState({
      articleAmountList: 'additionalArticleAmountList',
      guideTab: state => state.guideTab,
    }),
  },
  data () {
    return {
      path: [],
      publicPath: process.env.BASE_URL,
      tab: 0,
    }
  },

  mounted() {
  },
  methods: {
    ...mapMutations([
      'setGuideStep',
      'setGuideTab',
      'resetConfiguration',
      'setAdditionalArticleList',
      'setAdditionalArticleCount',
      'setCurrentAdditionalProduct',
    ]),
    navigateToConfigurator() {
      this.setGuideTab(0);
    },
    navigateToAdditionalProducts() {
      this.setGuideTab(1);
    },
    navigateToCheckout() {
      this.setGuideTab(2);
    },
  },
}
</script>

<style scoped>
  .amount{
    width: 120px;
  }
  .v-card__text, .v-card__title {
    word-break: normal !important;
  }
</style>
