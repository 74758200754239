import {SIDE_OFFSET as FSIDE_OFFSET} from '../../components/presentation/drawings/FSide.vue'
import {SIDE_OFFSET as WSIDE_OFFSET} from '../../components/presentation/drawings/WSide.vue'

export function getSideOffsetX(product, curr, prev) {
  if(!product) {
    return 0;
  }
  if(prev.type==='interrupt'){
    switch(product.key) {
      case 'product-fussgaengerschutz':
        return 0;
      case 'product-guardrail':
        return 0;
      case 'product-palettenspeicher':
        return 0;
      case 'product-wandschutz':
        return 0;
    }
  }
  let prevAngle = prev.angle;
  let prevLength = prev.length;
  switch(product.key) {
    case 'product-fussgaengerschutz':
      return FSIDE_OFFSET + (Math.max(Math.ceil(prevLength / 1500), 1)- 1 ) * 24 + (prev.custom_angle ? 150 : 0);
    case 'product-guardrail':
    case 'product-palettenspeicher':
       return 146 + (Math.max(Math.ceil(prevLength / 3000), 1)- 1 ) * (145) + (prev.custom_angle ? 360 : 0);
    case 'product-wandschutz':
      if(curr.type==='interrupt') return WSIDE_OFFSET + (prev.custom_angle ? 5 : 0);
      if(prev.custom_angle) return WSIDE_OFFSET + (Math.max(Math.ceil(prevLength /3000), 1) - 1) * 18 + (prev.custom_angle ? 5 : 0);
      if(prevAngle == 90) {
        return WSIDE_OFFSET -49 + (Math.max(Math.ceil(prevLength /3000), 1) - 1) * 18 + (prev.custom_angle ? 5 : 0);
      } else if(prevAngle == 270) {
        return WSIDE_OFFSET - 48 + (Math.max(Math.ceil(prevLength /3000), 1) - 1) * 18 + (prev.custom_angle ? 5 : 0);
      }else if(prevAngle == 45) {
        return WSIDE_OFFSET -26 + (Math.max(Math.ceil(prevLength /3000), 1) - 1) * 18 + (prev.custom_angle ? 5 : 0);
      }else if(prevAngle == 315) {
        return WSIDE_OFFSET -20 + (Math.max(Math.ceil(prevLength /3000), 1) - 1) * 18 + (prev.custom_angle ? 5 : 0);
      } else {
        return WSIDE_OFFSET + (prev.custom_angle ? 5 : 0);
      }
    default:
      return 0;
  }
}

export function getSideOffsetY(product, curr, prev) { // eslint-disable-line no-unused-vars
  if(!product) {
    return 0;
  }
  if(prev.type==='interrupt'){
    switch(product.key) {
      case 'product-fussgaengerschutz':
        return -25;
      case 'product-guardrail':
        return 0;
      case 'product-palettenspeicher':
        return 0;
      case 'product-wandschutz':
        return 0;
    }
  }
  let prevAngle = prev.angle;
  switch(product.key) {
    case 'product-fussgaengerschutz':
      return FSIDE_OFFSET-25;
    case 'product-wandschutz':
      if(curr.type == 'interrupt') return 0;
      if(prev.custom_angle) return 0;
      if(prevAngle == 90) {
        return 128;
      } else if(prevAngle == 270) {
        return -126;
      }else if(prevAngle == 45) {
        return 65;
      }else if(prevAngle == 315) {
        return -63
      }else{
        return 0;
      }
    case 'product-guardrail':
      return 0;
    default:
      return 0;
  }
}
