import {default as getPartListFussgaengerschutz} from './fussgaengerschutz'
import {default as getPartListGuardrail} from './guardrail'
import {default as getPartListPalettenschutz} from './palettenschutz'
import {default as getPartListWandschutz} from './wandschutz'

function getPartListForSide(product, anchoring, productDetails, shaping, curr, prev, next) {
  if(!product) {
    return [];
  }
  if(!productDetails) {
    productDetails = {}
  }
  let fussbodenheizung = anchoring && anchoring.key == 'anchoring-fussbodenheizung'
  switch(product.key) {
    case 'product-fussgaengerschutz':
      return getPartListFussgaengerschutz(fussbodenheizung, productDetails, shaping, curr, prev, next);
    case 'product-guardrail':
      return getPartListGuardrail(fussbodenheizung, productDetails, shaping, curr, prev, next);
    case 'product-palettenspeicher':
      return getPartListPalettenschutz(fussbodenheizung, productDetails, shaping, curr, prev, next);
    case 'product-wandschutz':
      return getPartListWandschutz(fussbodenheizung, productDetails, shaping, curr, prev, next);
  }
}

/*function getPartListGuardrail(fussbodenheizung, productDetails, side, prev, next) {

}*/


export default getPartListForSide;
