<template>
  <v-card
    style="height: 100%"
  >
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-container fluid>
      <p>
        Nachfolgend können Sie Ihr Wunschprodukt näher konfigurieren und anschließend die gewünschte Stückzahl auswählen:
      </p>
      <v-row>
      <v-col
        class="d-flex"
        cols="12"
        md="6"
        v-for="[key, label] in Object.entries(attributes)"
        :key="key"
      >
        <v-select
          :items="attributeItems[key]"
          :label="label"
          v-model="selectedAttributes[key]"
        ></v-select>
      </v-col>
      </v-row>
      <p>
        Ihr konfiguriertes Wunschprodukt:
      </p>
      <template v-if="matchingProducts.length === 0">
        <i>Für Ihre Auswahl wurden keine Produkte gefunden.</i>
      </template>
      <template v-else>
        <center
          style="width: 100%"
        >
          <v-col
            sm="12"
            lg="6"
            xl="4"
          >
            <v-card
              hover
              style="height: 100%"
              class="d-flex flex-column"
            >
              <v-img
                :src="publicPath + matchingProducts[0].image"
                height="150"
                contain
              ></v-img>
              <v-spacer></v-spacer>
              <template v-if="matchingProduct">
                <v-card-title>
                  {{ matchingProduct.name }}
                </v-card-title>
                <v-card-actions class="justify-center">
                  <v-text-field 
                    label="Menge"
                    v-model="newAmount"
                    append-outer-icon="mdi-plus"
                    prepend-icon="mdi-minus"
                    @click:append-outer="newAmount ++"
                    @click:prepend="newAmount > 0 ? newAmount -- : false"
                    class="shrink mt-0 pt-0 amount">
                  </v-text-field>
                </v-card-actions>
                <v-card-actions class="justify-end">
                  <v-btn
                    color="primary"
                    :disabled="newAmount === 0"
                    @click="matchingProduct.amount += newAmount; newAmount = 0"
                  >
                    Hinzufügen
                  </v-btn>
                </v-card-actions>
              </template>
              <template v-else>
                <i class="ml-3 mt-3 mb-3 mr-3">Bitte konkretisieren Sie Ihre Auswahl, um das richtige Produkt zu finden</i>
              </template>
            </v-card>
          </v-col>
        </center>
      </template>
    </v-container>
    <v-snackbar
      v-model="success"
      color="green"
      rounded="pill"
      centered
      timeout="5000"
    >
      Ihre Konfiguration wurde hinzugefügt.

      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          text
          v-bind="attrs"
          @click="success = false"
        >
          Ok.
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
export default {
  name: 'AdditionalProductsLeitplanke',
  components: {
  },
  props: {
    products: Array,
    attributes: Object,
    title: String,
  },
  computed: {
    attributeItems() {
      let attributeItems = {}
      this.products.forEach(product => {
        for (const [key, value] of Object.entries(product.attributes)) {
          if(!(key in attributeItems)) {
            attributeItems[key] = []
          }
          attributeItems[key].push(value)
          attributeItems[key].sort();
        }
      });
      return attributeItems;
    },
    matchingProducts() {
      return this.products.filter(product => {
        for (const [key, value] of Object.entries(this.selectedAttributes)) {
          if(key in product.attributes && product.attributes[key] !== value) {
            return false;
          }
        }
        return true;
      })
    },
    matchingProduct() {
      return this.matchingProducts && this.matchingProducts.length === 1 ? this.matchingProducts[0] : null;
    }
  },
  data () {
    return {
      selectedAttributes: {},
      publicPath: process.env.BASE_URL,
      variant: null,
      length: 3000,
      color: null,
      anchoring: null, 
      success: false,
      newAmount: 0,
    }
  },
  methods: {
  },
  watch: {
    matchingProducts(value) {
      if(value && value.length > 0) {
        this.$emit('change', value[0])
      }
    },
  }
}
</script>

<style scoped>
  .amount{
    width: 120px;
  }
  .v-card__text, .v-card__title {
    word-break: normal !important;
  }
</style>
