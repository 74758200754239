<template>
  <v-card
    @click="$emit('click')"
    :outlined="selected">
    <v-img v-if="image"
      height="150"
      :src="image"
      contain
    ></v-img>

    <v-tooltip
      bottom
      v-if="downloadButton"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          class="download-btn"
          elevation="2"
          @click.stop="downloadFile(downloadButton.url)"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </template>
      <span>{{ downloadButton.text }}</span>
    </v-tooltip>

    <v-card-text class="text-center">{{ title }}</v-card-text>
  </v-card>
</template>

<script>

export default {
  name: 'Card',
  props: {
    title: String,
    image: String,
    downloadButton: {
      type: Object,
      default: null,
      required: false,
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    downloadFile(url) {
      window.open(url, '_blank');
    },
  },
}
</script>

<style scoped>
  .v-sheet--outlined {
    border-color: green!important;
  }
  .download-btn {
    background-color: white;
    position: absolute;
    top: 5px;
    right: 5px;
  }
</style>
