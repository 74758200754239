import {default as ARTICLES} from './wandschutz.articles';

function getPartListFussgaengerschutz(fussbodenheizung, productDetails, shaping, side, prev, next) { // eslint-disable-line no-unused-vars

  if(side.type == 'interrupt') {
    return [];
  }

  let parts = [];
  let aufgedoppelt = productDetails.key == 'aufgedoppelt';
  let endkonsoleArtNo = aufgedoppelt ? (fussbodenheizung ? ARTICLES.ENDKONSOLE_AUFGEDOPPELT_FUSSBODENHEIZUNG : ARTICLES.ENDKONSOLE_AUFGEDOPPELT_NORMAL) : (fussbodenheizung ? ARTICLES.ENDKONSOLE_FLACH_FUSSBODENHEIZUNG : ARTICLES.ENDKONSOLE_FLACH_NORMAL);
  let mittelkonsoleArtNo = aufgedoppelt ? (fussbodenheizung ? ARTICLES.MITTELKONSOLE_AUFGEDOPPELT_FUSSBODENHEIZUNG : ARTICLES.MITTELKONSOLE_AUFGEDOPPELT_NORMAL) : (fussbodenheizung ? ARTICLES.MITTELKONSOLE_FLACH_FUSSBODENHEIZUNG : ARTICLES.MITTELKONSOLE_FLACH_NORMAL);
  let eckkonsoleArtNo = aufgedoppelt ? (fussbodenheizung ? ARTICLES.ECKKONSOLE_AUFGEDOPPELT_FUSSBODENHEIZUNG : ARTICLES.ECKKONSOLE_AUFGEDOPPELT_NORMAL) : (fussbodenheizung ? ARTICLES.ECKKONSOLE_FLACH_FUSSBODENHEIZUNG : ARTICLES.ECKKONSOLE_FLACH_NORMAL);
  let eckkonsole45ArtNo = aufgedoppelt ? (fussbodenheizung ? ARTICLES.ECKKONSOLE_AUFGEDOPPELT_FUSSBODENHEIZUNG_45 : ARTICLES.ECKKONSOLE_AUFGEDOPPELT_NORMAL_45) : (fussbodenheizung ? ARTICLES.ECKKONSOLE_FLACH_FUSSBODENHEIZUNG_45 : ARTICLES.ECKKONSOLE_FLACH_NORMAL_45);
  let barriere3000ArtNo = ARTICLES.BARRIERE_3000;
  let barriere1500ArtNo = ARTICLES.BARRIERE_1500;
  //let barriere1160ArtNo = ARTICLES.BARRIERE_1160;
  if(side.is_first) {
    if(shaping.key == 'shaping-o') {
      parts.push(eckkonsoleArtNo);
    } else {
      parts.push(endkonsoleArtNo);
    }
  }

  let partLength     = 2900;
  let numberOfSteps  = Math.floor(side.length / partLength);
  let lastPartLength = side.length - numberOfSteps * partLength;

  for(let i = 0; i < numberOfSteps; i++) { // last part will be added later
    parts.push(barriere3000ArtNo);
    if(aufgedoppelt) {
      parts.push(barriere3000ArtNo);
    }
  }
  // add last part

  if(lastPartLength > 0) {
    let lastPartArtNo = 0;
    let lastPartOriginalLength = 0;
    if(numberOfSteps > 0) {
      parts.push(mittelkonsoleArtNo);
    }

    /*if(lastPartLength <= 1160) {
      lastPartArtNo = barriere1160ArtNo;
      lastPartOriginalLength = 1160;
    }else*/ if(lastPartLength <= 1450) {
      lastPartArtNo = barriere1500ArtNo;
      lastPartOriginalLength = 1450;
    }else{
      lastPartArtNo = barriere3000ArtNo;
      lastPartOriginalLength = 2900;
    }

    if(lastPartOriginalLength != lastPartLength) {
      let lastPart = {
        'article_number': lastPartArtNo,
        'original_length': lastPartOriginalLength,
        'length': lastPartLength,
      };
      parts.push(Object.assign({}, lastPart));
      if(aufgedoppelt) {
        parts.push(Object.assign({}, lastPart))
      }
    } else {
      parts.push(lastPartArtNo);
      if(aufgedoppelt) {
        parts.push(lastPartArtNo);
      }
    }
  }

  for(let i = 0; i < numberOfSteps - 1; i++) {
    parts.push(mittelkonsoleArtNo);
  }
  if(!side.is_last) {
    if(side.angle == 90 || side.angle == 270) {
      parts.push(eckkonsoleArtNo);
    }
    if(side.angle == 45 || side.angle == 315) {
      parts.push(eckkonsole45ArtNo);
    }
  }
  if(side.is_last && shaping.key != 'shaping-o') { // if shaping is rectangle we do not need an endkonsole - we use eckkonsole as first node)
    parts.push(endkonsoleArtNo);
  }
  return parts;
}

export default getPartListFussgaengerschutz;