<template>
  <v-card
    style="height: 100%"
  >
    <v-card-title>
      Säulenschutz aus Fußgängerschutz konfigurieren & hinzufügen
    </v-card-title>
    <v-container fluid>
      <v-row>
        <v-col
          class="d-flex"
          cols="12"
          md="6"
        >
          <v-select
            :items="['Normal', 'Fußbodenheizung']"
            label="Verankerungsart"
            v-model="anchoring"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="d-flex"
          cols="12"
          md="6"
        >
          <v-text-field
            :min="0"
            label="Länge A"
            type="number"
            v-model="length_a"
            suffix="cm"
          ></v-text-field>
        </v-col>
        <v-col
          class="d-flex"
          cols="12"
          md="6"
        >
          <v-text-field
            :min="0"
            label="Länge B"
            type="number"
            v-model="length_b"
            suffix="cm"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col></v-col>
        <v-col>
          <v-btn
            color="primary"
            :disabled="!anchoring || !length_a || !length_b"
            @click="addConfiguration"
          >
            Hinzufügen
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="success"
      color="green"
      rounded="pill"
      centered
      timeout="5000"
    >
      Ihre Konfiguration wurde hinzugefügt.

      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          text
          v-bind="attrs"
          @click="success = false"
        >
          Ok.
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
export default {
  name: 'AdditionalProductsSaeulenschutzFussgaenger',
  components: {
  },
  props: {
    element: Object,
  },
  computed: {
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      length_a: 3000,
      length_b: 3000,
      anchoring: null,
      success: false,
    }
  },
  methods: {
    addConfiguration() {
      this.element.configurations.push({
        name: 'Säulenschutz aus Fußgängerschutz ' + this.anchoring + ' ' + this.length_a + 'x' + this.length_b + 'cm',
        parts: [] //TODO
      })
      this.success = true;
    },
  },
  watch: {
  }
}
</script>

<style scoped>
  .amount{
    width: 120px;
  }
  .v-card__text, .v-card__title {
    word-break: normal !important;
  }
</style>
