<template>
  <div>
    <Header
      :subtitle="(selectedAnchoring) ? selectedAnchoring.name : ''"
      :title="'Verankerungsart'"
      :type="(selectedAnchoring) ? 'success' : undefined"
    />
    <v-expansion-panel-content>
      <p>Bitte wählen Sie die gewünschte Verankerungsart:</p>
      <v-row>
        <v-col
          v-for="anchoring in anchorings"
          :key="anchoring.key"
          cols="6"
        >
          <Card
            :title="anchoring.name"
            :selected="selectedAnchoring && selectedAnchoring.key === anchoring.key"
            @click="setAnchoring(anchoring)"
          />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </div>
</template>

<script>
import Card from './parts/Card.vue'
import Header from './parts/Header.vue'
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'Anchoring',
  components: {
    Card,
    Header,
  },
  props: {
  },
  computed: {
    ...mapGetters({
      enabled: 'isProductConfigured'
    }),
    ...mapState({
      selectedAnchoring: state => state.anchoring
    })
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      anchorings: [
        {
          'key': 'anchoring-normal',
          'name': 'Normal',
          'image': 'images/anchorings/normal.jpg',
        },
        {
          'key': 'anchoring-fussbodenheizung',
          'name': 'Fußbodenheizung',
          'image': 'images/anchorings/fussbodenheizung.jpg',
        }
      ]
    }
  },
  methods: {
    ...mapMutations([
      'setAnchoring',
    ]),
  },
}
</script>

<style scoped>
</style>
