<template>
  <v-card class="mr-2">
    <v-tabs
      v-model="tab"
      grow
    >
      <v-tab :key="1" >
        Aufsicht
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item :key="1">
        <v-card flat>
            <Drawing />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import Drawing from './Drawing.vue'
export default {
  name: 'DrawingTab',
  props: {
  },
  components: {
    Drawing,
  },
  data () {
    return {
      tab: 1,
    }
  },  
  computed: {
   
  },
  mounted() {
  },
  watch: {
  },
  methods: {
  }

}
</script>

<style scoped>
</style>

