<template>
 <div>
    <Header
      :title="title"
      :subtitle="(selectedProductDetails) ? selectedProductDetails.text : ''"
      :type="(this.isProductConfigured) ? 'success' : undefined"
    />
    <v-expansion-panel-content>
      <template v-if="selectedProduct && selectedProduct.key === 'product-fussgaengerschutz'">
        <p>Wünschen Sie einen zusätzlichen Fußgängerschutz?</p>
        <v-row>
          <v-col
            cols="6"
          >
            <Card
              :image="publicPath + 'images/products/fussgaengerschutz-mit-staplerbarriere.jpg'"
              :title="'Mit Staplerbarriere'"
              :selected="this.selectedProductDetails && this.selectedProductDetails.key === 'mit-staplerbarriere'"
              @click="setProductDetails({
                key: 'mit-staplerbarriere',
                text: 'Mit Staplerbarriere',
              })"
            />
          </v-col>
          <v-col
            cols="6"
          >
            <Card
              :image="publicPath + 'images/products/fussgaengerschutz-ohne-staplerbarriere.jpg'"
              :title="'Ohne Staplerbarriere'"
              :selected="this.selectedProductDetails && this.selectedProductDetails.key === 'ohne-staplerbarriere'"
              @click="setProductDetails({
                key: 'ohne-staplerbarriere',
                text: 'Ohne Staplerbarriere',
              })"
            />
          </v-col>
        </v-row>
      </template>
      <template v-if="selectedProduct && selectedProduct.key === 'product-wandschutz'">
        Bitte wählen Sie eine Variante:
        <v-row>
          <v-col
            cols="6"
          >
            <Card
              :image="publicPath + 'images/products/wandschutz-flach.jpg'"
              :title="'Höhe 265'"
              :selected="this.selectedProductDetails && this.selectedProductDetails.key === 'flach'"
              @click="setProductDetails({
                key: 'flach',
                text: 'Höhe 265',
              })"
            />
          </v-col>
          <v-col
            cols="6"
          >
            <Card
              :image="publicPath + 'images/products/wandschutz-aufgedoppelt.jpg'"
              :title="'Höhe 495'"
              :selected="this.selectedProductDetails && this.selectedProductDetails.key === 'aufgedoppelt'"
              @click="setProductDetails({
                key: 'aufgedoppelt',
                text: 'Höhe 495',
              })"
            />
          </v-col>
        </v-row>
      </template>
      <template v-if="selectedProduct && selectedProduct.key === 'product-palettenspeicher'">
        <v-row>
          <v-col
            cols="12"
          >
            <p>Wie viele Barrieren wünschen Sie?</p>
            <v-slider
              label="Barrieren"
              :hint="barrier_amount + ''"
              :hide-details="false"
              :thumb-label="true"
              persistent-hint
              :max="Math.min(4, (Math.floor((height-30)/52.5) + 1))"
              :min="3"
              v-model="barrier_amount"
            />
          </v-col>
        </v-row>
        <v-row v-if="barrier_amount == 2">
          <div style="position: relative; width: 100%">
            <img style="right: 0; top:0" :src='"/images/products/palettenspeicher/barrieren-" + barrier_amount + "-s.png"'/>
            <v-text-field style="position:absolute; left: 0; top:50%;width:90px; background-color:white" suffix="mm" :length="3" type="number" :min="barrier_distances_min[0]" :max="barrier_distances_max[0]" :rules="[rules.min(barrier_distances_min[0], barrier_distance_0), rules.max(barrier_distances_max[0], barrier_distance_0)]" v-model.number="barrier_distance_0"></v-text-field>
          </div>
        </v-row>
        <v-row v-if="barrier_amount == 3">
          <div style="position: relative; width: 100%">
            <img style="right: 0; top:0" :src='"/images/products/palettenspeicher/barrieren-" + barrier_amount + "-s.png"'/>
            <v-text-field style="position:absolute; left: 0; top:23%;width:90px; background-color:white" suffix="mm" :length="3" type="number" :min="barrier_distances_min[1]" :max="barrier_distances_max[1]" v-model.number="barrier_distance_1"></v-text-field>
            <v-text-field style="position:absolute; left: 70px; top:65%;width:90px; background-color:white" suffix="mm" :length="3" type="number" :min="barrier_distances_min[0]" :max="barrier_distances_max[0]" v-model.number="barrier_distance_0"></v-text-field>
          </div>
        </v-row>
        <v-row v-if="barrier_amount == 4">
          <div style="position: relative; width: 100%">
            <img style="right: 0; top:0" :src='"/images/products/palettenspeicher/barrieren-" + barrier_amount + "-s.png"'/>
            <v-text-field style="position:absolute; left: 0; top:17%;width:90px; background-color:white" suffix="mm" :length="3" type="number" :min="barrier_distances_min[2]" :max="barrier_distances_max[2]" v-model.number="barrier_distance_2"></v-text-field>
            <v-text-field style="position:absolute; left: 40px; top:40%;width:90px; background-color:white" suffix="mm" :length="3" type="number" :min="barrier_distances_min[1]" :max="barrier_distances_max[1]" v-model.number="barrier_distance_1"></v-text-field>
            <v-text-field style="position:absolute; left: 92px; top:70%;width:90px; background-color:white" suffix="mm" :length="3" type="number" :min="barrier_distances_min[0]" :max="barrier_distances_max[0]" v-model.number="barrier_distance_0"></v-text-field>
          </div>
        </v-row>
        <v-row :justify="'end'">
          <v-btn
            color="primary"
            elevation="2"
            @click="setGuideStep(3)"
          >Weiter</v-btn>
        </v-row>
      </template>
    </v-expansion-panel-content>
  </div>
</template>

<script>
import Card from './parts/Card.vue'
import Header from './parts/Header.vue'
import { mapGetters, mapMutations, mapState } from 'vuex'
import Vue from 'vue';

export default {
  name: 'ProductDetails',
  components: {
    Card,
    Header,
  },
  props: {
  },
  computed: {
    title() {
      if(!this.selectedProduct) return '...';
      switch(this.selectedProduct.key) {
        case 'product-fussgaengerschutz':
          return 'Optionale Staplerbarriere';
        case 'product-palettenspeicher':
          return 'Barrieren';
        case 'product-wandschutz':
          return 'Wahl der Variante';
        default:
          return '..';
      }
    },
    ...mapGetters({
      isProductConfigured: 'isProductConfigured'
    }),
    ...mapState({
      selectedProduct: state => state.product,
      selectedProductDetails: state => state.productDetails,
    }),
    barrier_distances_max() {
      switch(this.barrier_amount) {
        case 2:
          return [this.height*10-300, -1, -1];
        case 3:
          return [this.barrier_distances[1]-this.barrier_distance_min, this.height*10-300, -1];
        case 4:
          return [this.barrier_distances[1]-this.barrier_distance_min, this.barrier_distances[2]-this.barrier_distance_min, this.height*10-300];
        default:
          return [-1, -1, -1];
      }
    },
    barrier_distances_min() {
      switch(this.barrier_amount) {
        case 2:
          return [this.barrier_distance_min, -1, -1];
        case 3:
          return [this.barrier_distance_min, this.barrier_distances[0]+this.barrier_distance_min, -1];
        case 4:
          return [this.barrier_distance_min, this.barrier_distances[0]+this.barrier_distance_min, this.barrier_distances[1]+this.barrier_distance_min];
        default:
          return [-1, -1, -1];
      }
    },
    barrier_distance_0: {
      get () {
        return this.barrier_distances[0];
      },
      set (value) {
        let i = 0;
        Vue.set(this.barrier_distances, i, Math.max(this.barrier_distances_min[i], Math.min(value, this.barrier_distances_max[i])))
      }
    },
    barrier_distance_1: {
      get () {
        return this.barrier_distances[1];
      },
      set (value) {
        let i = 1;
        Vue.set(this.barrier_distances, i, Math.max(this.barrier_distances_min[i], Math.min(value, this.barrier_distances_max[i])))
      }
    },
    barrier_distance_2: {
      get () {
        return this.barrier_distances[2];
      },
      set (value) {
        let i = 2;
        Vue.set(this.barrier_distances, i, Math.max(this.barrier_distances_min[i], Math.min(value, this.barrier_distances_max[i])))
      }
    },
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      height: 220,
      barrier_amount: 3,
      barrier_distances: [0,0,0],
      barrier_distance_min: 525,
      rules: {
        min(min, v) {
          return v >= min || 'Mindestens ' + min + 'mm' + v;
        },
        max(max, v) {
          return v <= max || 'Maximal ' + max + 'mm';
        }
      }
    }
  },
  watch: {
    height() {
      this.setBarrierDistanceDefault()
      this.setProductDetailsNow();
    },
    barrier_amount() {
      this.setBarrierDistanceDefault()
      this.setProductDetailsNow();
    },
    barrier_distances: function(newDistances, oldDistances) {
      for(let i = 0; i < 3; i++) {
        if(newDistances[i] != oldDistances[i]) {
          Vue.set(this.barrier_distances, i, Math.max(this.barrier_distances_min[i], Math.min(this.barrier_distances[i], this.barrier_distances_max[i])));
        }
      }
    }
  },
  mounted() {
    this.setBarrierDistanceDefault();
  },
  methods: {
    ...mapMutations([
      'setGuideStep',
      'setProductDetails',
    ]),
    setProductDetailsNow() {
      this.setProductDetails({
        text: this.height + 'cm, ' + this.barrier_amount + ' Barrieren',
        height: this.height,
        barrier_amount: this.barrier_amount,
        barrier_distance: this.barrier_distances,
      })
    },
    setBarrierDistanceDefault() {
      let d = Math.round((this.height*10 - 300 - (this.barrier_amount - 1) * this.barrier_distance_min) / (this.barrier_amount - 1))
      
      this.barrier_distance_0 = d + this.barrier_distance_min
      this.barrier_distance_1 = (d+this.barrier_distance_min)*2
      this.barrier_distance_2 = (d+this.barrier_distance_min)*3
    }
  },
}
</script>

<style scoped>
</style>
