<template>
  <v-row>
    <v-col
      cols="12"
      sm="12"
      md="4"
    >
      <v-subheader>
        <span class="side">{{ side.name }}</span>
        <v-menu
          bottom
          left
          open-on-hover
          v-if="removable"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="secondary">mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item-group>
              <v-list-item  v-if="side.type == 'side'" @click="addInterrupt">
                <v-list-item-content>
                  <v-list-item-title>Unterbrechung hinzufügen</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="removable" @click="remove">
                <v-list-item-title>Entfernen</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-subheader>
      
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="4"
    >
      <v-text-field
        label="Länge"
        v-model="length"
        :rules="[rules.required, rules.validLength]"
        :messages="hasLengthError ? undefined : hasToBeTailoredMessage"
        @update:error="(e) => hasLengthError = e"
        suffix="mm"
        :type="'number'"
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="4"
    >
      <!-- slider -->
      <div v-if="!isLast && (side.type==='interrupt' || successorSide && successorSide.type==='interrupt' || customAngle)">
        <label for="input-1029" class="v-label v-label--active theme--light" style="font-size:12px">Winkel</label><br/>
      <v-slider
        :hide-details="false"
        :thumb-label="true"
        persistent-hint
        :max="customAngle ? 359 : Math.max(...validAngles)"
        :min="customAngle ? 0 : Math.min(...validAngles)"
        v-model="angle"
        :rules="[rules.required, isValidAngle]"
        @update:error="(e) => hasAngleError = e"
        :disabled="!isAngleEditable"
      >
        <template v-slot:append>
          <v-text-field
            v-model="angle"
            class="mt-0 pt-0"
            hide-details
            single-line
            :rules="[rules.required, isValidAngle]"
            @update:error="(e) => hasAngleError = e"
            type="number"
            style="width: 60px"
          ></v-text-field>

          <v-menu offset-y left>
            <template v-slot:activator="{ on, attrs }">  
              <v-icon
                v-bind="attrs"
                v-on="on"
              >mdi-menu-down</v-icon>
            </template>
            <v-list>
              <v-list-item-group>
                <v-list-item
                  v-for="(item, index) in validAnglesExceptAllAngles"
                  :key="index"
                >
                  <v-list-item-content @click="() => {angle = item; side.set('custom_angle', false); customAngle = false}">
                    <v-list-item-title>{{ item }}°</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </template>
      </v-slider>
</div>
      <!-- select -->
      <v-select
        v-else-if="!isLast"
        :items="[-1].concat(validAngles)"
        v-model="angle"
        label="Winkel"
        :disabled="!isAngleEditable"
        :rules="[rules.required, isValidAngle]"
        @update:error="(e) => hasAngleError = e"
      >
        <template v-slot:item="{item}">
          <template v-if="item === -1">
            Freier Winkel
          </template>
          <template v-else>
            {{item}}°
          </template>
        </template>
        <template v-slot:selection="{item}">{{item}}°</template>
      </v-select>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'DimensionsSide',
  components: {
  },
  props: {
    side: Object,
    successorSide: Object,
    isLast: Boolean,
    index: Number,
    removable: Boolean,
    isAngleEditable: Boolean,
    syncLengthWith: Array,
  },
  computed: {
    ...mapGetters({
      enabled: 'isProductConfigured'
    }),
    ...mapState({
      product: state => state.product,
      productDetails: state => state.productDetails,
    }),
    length: {
      get () {
        return this.side.length;
      },
      set (value) {
        if(value && this.isValidLength(value) === true) {
          let l = Number.parseInt(value);
          this.side.set('length', l)
          if(this.syncLengthWith) {
            this.syncLengthWith.forEach((s) => {
              s.set('length', l)
            })
          }
        }
      }
    },
    angle: {
      get () {
        return this.side.angle;
      },
      set (value) {
        this.side.set('custom_angle', this.isAngleSupportedWithoutCustomAngle(value) !== true);
        if(this.isValidAngle(value) === true) {
          this.side.set('angle', Number.parseInt(value))
        }else{
          if(value != this.side.angle){
            // reset invalid value
            this.angle = this.side.angle;
          }
          if(value === -1) {
            this.customAngle = true;
          }
        }
      }
    },
    lastPartLength() { // [partLength, cuttedLength]
      let cuttedPart = this.side.parts.filter(p => typeof p === 'object').shift();
      if(!cuttedPart) {
        return null;
      }
      return[cuttedPart.original_length, cuttedPart.length];
    },
    hasToBeTailoredMessage() {
      if(this.lastPartLength && this.lastPartLength[0] != this.lastPartLength[1] && this.side.type === 'side') {
        //let msg = 'Ein Teil muss von ' + this.lastPartLength[0] + 'mm Lieferlänge auf ' + this.lastPartLength[1] + 'mm selbst zugeschnitten werden.'
        let msg = 'Mind. ein Teil muss selbst zugeschnitten werden.'
        if(this.product.key == 'product-fussgaengerschutz' && this.lastPartLength[1] < 400 && this.productDetails && this.productDetails.key == 'mit-staplerbarriere') {
          msg += " Bei Teilen mit einer Länge kleiner 400mm wird keine Staplerbarriere eingeplant."
        }
        return msg;
      } else {
        return undefined;
      }
    },
    validAnglesExceptAllAngles() {
      if(!this.product) {
        return [];
      }
      switch(this.product.key) {
        case 'product-fussgaengerschutz':
        case 'product-palettenspeicher':
        case 'product-guardrail':
          return [90, 270];
        case 'product-wandschutz':
          return [45, 90, 270, 315];
        default:
          return [];
      }
    },
    validAngles() {
      if(!this.product) {
        return [];
      }
      if(this.side.type === 'interrupt' || this.successorSide && this.successorSide.type === 'interrupt') {
        let angles = [];
        for(let i = 0; i <= 359; i++) {
          angles.push(i);
        }
        return angles;
      }
      return this.validAnglesExceptAllAngles;
    },
  },
  data () {
    return {
      customAngle: false,
      hasAngleError: false,
      hasLengthError: false,
      publicPath: process.env.BASE_URL,
      rules: {
        required: value => (!!value || value === 0) || 'Erforderlich.',
        validLength: this.isValidLength,
      },
    }
  },
  mounted(){
    this.customAngle = this.side.custom_angle;
  },
  methods: {
    ...mapMutations([
      'addSide',
      'setAnchoring',
      'removeSide',
    ]),
    isValidLength: function(value) {
      let valid = !isNaN(value) && Number.isInteger(parseFloat(value));
      let min = (this.product && ['product-wandschutz'].includes(this.product.key)) ? 300 : 1;
      if(valid) {
        let i = parseFloat(value);
        valid = valid && i >= min && i < 500001;
      }else {
        return 'Natürliche Zahl erwartet.';
      }
      return valid || 'Die Länge muss mindestens ' + min + 'mm und kleiner 500m sein.';
    },
    isValidAngle: function(value) {
      if(this.isLast) {
        return true;
      }
      let valid = !isNaN(value) && Number.isInteger(parseFloat(value));
      if(!valid) {
        return "Natürliche Zahl erwartet."
      }
      if(this.side.custom_angle || this.customAngle){
        return true;
      }
      let a = this.isAngleSupportedWithoutCustomAngle(value);
      if(a !== true) {
        return "Winkel muss einer der folgenden Werte sein: " + a.join(', ');
      }
      return true;
    },
    /**
     * verifies if a connector for given angle exists
     * return true if this is the case - or an array of angles for which in connector exists else.
     */
    isAngleSupportedWithoutCustomAngle(value){
      let i = parseFloat(value);
      let valid = this.validAngles.includes(i);
      if(!valid) {
        return this.validAngles;
      }
      return true;
    },
    remove() {
      this.removeSide(this.index)
    },
    addInterrupt() {
      this.customAngle = true;
      this.addSide({
        index: this.index + 1,
        side: {
          'type': 'interrupt',
          'length': 1000,
          'angle': this.angle,
        }
      });
      this.angle = 0;
    }
  },
  watch: {
    customAngle(value) {
      if(!value && this.isAngleSupportedWithoutCustomAngle(this.angle) !== true) {
        this.angle=90;
      }
    }
  }
}
</script>

<style scoped>
  .side{
    font-size: 16px;
    font-weight: inherit;
    color: #000;
    margin-right: .4rem;
  }

  .delete{
    font-size: .7rem;
  }
</style>
