<template>
  <v-card
    hover
    style="height: 100%"
    class="d-flex flex-column"
  >
    <v-card-title>
      {{ product.name }}
    </v-card-title>
    <v-spacer></v-spacer>
    <v-img
      :src="publicPath + product.image"
      height="150"
      contain
    ></v-img>
    <v-card-actions class="justify-center">
      <v-text-field 
        label="Menge"
        v-model="newAmount"
        append-outer-icon="mdi-plus"
        prepend-icon="mdi-minus"
        @click:append-outer="newAmount ++"
        @click:prepend="newAmount > 0 ? newAmount -- : false"
        class="shrink mt-0 pt-0 amount">
      </v-text-field>
    </v-card-actions>
    <v-card-actions class="justify-end">
      <v-btn
          color="primary"
          :disabled="newAmount === 0"
          @click="product.amount += newAmount; newAmount = 0"
        >
          Hinzufügen
        </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'AdditionalProductsProduct',
  components: {
  },
  props: {
    product: Object,
  },
  computed: {
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      newAmount: 0,
    }
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped>
  .amount{
    width: 120px;
  }
  .v-card__text, .v-card__title {
    word-break: normal !important;
  }
</style>
