<template>
  <v-card class="mr-2">
    <v-tabs
      v-model="tab"
      grow
    >
      <v-tab :key="1" >
        Produkt-Bilder
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item :key="1">
        <ProductInfos />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import ProductInfos from './ProductInfos.vue'

export default {
  name: 'ProductInfoTab',
  components: {
    ProductInfos
  },
  props: {
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      tab: 1,
    }
  },
  computed: {
  },
}
</script>

<style scoped>
</style>
