<template>
  <g>
    <WNode :transform="'translate(0 0)'" v-if="isFirst" />
    <g v-for="i in (numberOfParts - 1)" :key="i">
      <WEdge :transform="'translate(' + ((i-1)*(partLength + 18 + (i ==1 ? 0:0)) - 89) + ' 0)'" :length="partLength - (i == 1 ? offset1 : 0)" :isFirstSideElement="i==1" :hasSideLabel="numberOfParts > 1"/>
      <WNode :transform="'translate(' + (((i)*(partLength + 39 ) + ((i-1) * (-21))) - 89) + ' 0)'" :angle1="0" :angle2="180"/>
    </g>
    <WEdge
      :transform="'translate(' + ((numberOfParts-1)*(partLength + 18) - 89) + ' 0)'"
      :lengthMm="lastPartLengthMm"
      :originalLengthMm="lastPartOriginalLengthMm"
      :length="lastPartLength - offset2 - (numberOfParts == 1 ? offset1 : 0)"
      :isFirstSideElement="numberOfParts == 1"
      :isLastSideElement="true"
      :hasSideLabel="numberOfParts > 1"
    />
    <WNode v-if="!side.custom_angle || angle === null" :transform="'translate(' + ((numberOfParts - 1) * (partLength+18) + lastPartLength - 178) + ' 0)'" :angle1="180" :angle2="angle"/>
    <template v-else>
      <WNode :transform="'translate(' + ((numberOfParts - 1) * (partLength+18) + lastPartLength - 178) + ' 0)'" :angle1="180"/>
      <WNode :transform="'translate(' + ((numberOfParts - 1) * (partLength+18) + lastPartLength - 178) + ' 0) translate(180 0)'" :angle1="angle"/>
    </template>
    <Label
      transform="translate(-90 0)"
      :length="((numberOfParts-1)*partLength+lastPartLength)"
      :lengthMm="length"
      :type="'side'"
      v-if="numberOfParts > 1"
    />
  </g>
</template>

<script>
import { mapState } from 'vuex'

import Label from './Label.vue'

import WEdge from './WEdge.vue'
import {LENGTH_MM, LENGTH} from './WEdge.vue'

import WNode from './WNode.vue'
export const LENGTH_FACTOR = LENGTH_MM/LENGTH;
export const SIDE_OFFSET = 61;


export default {
  name: 'WSide',
  props: {
    side: Object,
    length: {
      type: Number,
      default: 1500,
    },
    angle: {
      type: Number,
      default: null,
    },
    isFirst: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    WEdge,
    WNode,
    Label,
  },
  computed: {
    ...mapState({
      sides: state => state.sides,
    }),
    offset1() {
      return ((!this.isFirst) ? 17 : 0);
    },
    offset2() {
      return ((this.angle == 90 || this.angle == 270) ? 17 : 0);
    },
    numberOfParts() {
      return Math.ceil(this.length / LENGTH_MM);
    },
    partLength() {
      return LENGTH;
    },
    lastPartLengthMm() {
      let mod = this.length % LENGTH_MM;
      if(mod === 0) {
        return LENGTH_MM;
      }
      return mod;
    },
    lastPartOriginalLengthMm() {
      let mod = this.length % LENGTH_MM;
      if(mod === 0) {
        return LENGTH_MM;
      }
      if(mod <= 1160) {
        return 1160;
      }
      if(mod <= 1500) {
        return 1500;
      }
      return LENGTH_MM;
    },
    lastPartLength() {
      return this.lastPartLengthMm * LENGTH / LENGTH_MM + SIDE_OFFSET
    }
  }

}
</script>

<style scoped>
</style>
