<template>
  <g>
    <Label :length="length" :lengthMm="length" :originalLengthMm="length" :type="'interrupt'" />
  </g>
</template>

<script>
import { mapState } from 'vuex'

import Label from './Label.vue'

export const LENGTH_MM = 3000;
export const LENGTH    = 3000;

export default {
  name: 'Interrupt',
  props: {
    length: {
      type: Number,
      default: LENGTH,
    },
    isVertex: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    Label,
  },
  computed: {
    ...mapState({
      sides: state => state.sides,
    }),
    originalLengthMm() {
      return LENGTH_MM;
    },
  }

}
</script>

<style scoped>
</style>
