<template>
  <g>
    <FNode :transform="'translate(0 0)'" v-if="isFirst" :mitStaplerbarriere1="mitStaplerbarriere && this.length >= 400"/>
    <g v-for="i in (numberOfParts - 1)" :key="i">
      <FEdge :transform="'translate(' + ((i-1) * partLength) + ' 0)'" :mitStaplerbarriere="mitStaplerbarriere" :isFirstSideElement="i==1" :hasSideLabel="numberOfParts > 1"/>
      <FNode :transform="'translate(' + ((i) * partLength) + ' 0)'" :angle1="0" :angle2="180" :mitStaplerbarriere2="mitStaplerbarriere" :mitStaplerbarriere1="mitStaplerbarriere && (lastPartLengthMm >= 400 || i != (numberOfParts-1))"/>
    </g>
    <FEdge
      :transform="'translate(' + ((numberOfParts - 1) * partLength) + ' 0)'" 
      :lengthMm="lastPartLengthMm"
      :length="lastPartLength"
      :mitStaplerbarriere="mitStaplerbarriere && this.length >= 400 && this.lastPartLengthMm >= 400"
      :isFirstSideElement="numberOfParts == 1"
      :isLastSideElement="true"
      :hasSideLabel="numberOfParts > 1"  
    />
    <FNode v-if="!side.custom_angle || angle === null" :transform="'translate(' + ((numberOfParts - 1) * partLength + lastPartLength) + ' 0)'" :angle1="180" :angle2="angle" :mitStaplerbarriere1="mitStaplerbarriere && this.length >= 400 && this.lastPartLengthMm >= 400" :mitStaplerbarriere2="mitStaplerbarriere && this.nextSideLength >= 400"/>
    <template v-else>
      <FNode :transform="'translate(' + ((numberOfParts - 1) * partLength + lastPartLength) + ' 0)'" :angle1="180" :mitStaplerbarriere1="mitStaplerbarriere && this.length >= 400"/>
      <FNode  :transform="'translate(' + ((numberOfParts - 1) * partLength + lastPartLength) + ' 0) translate(150 0)'" :mitStaplerbarriere1="mitStaplerbarriere && this.length >= 400" :angle1="angle"/>
    </template>
    <Label :length="((numberOfParts-1)*partLength+lastPartLength)" :lengthMm="length" :type="'side'" v-if="numberOfParts > 1" />
  </g>
</template>

<script>
import { mapState } from 'vuex'
import FEdge from './FBarriere.vue'
import {LENGTH_MM, LENGTH} from './FBarriere.vue'

import FNode from './FPfosten.vue'
export const LENGTH_FACTOR = LENGTH_MM/LENGTH;
export const SIDE_OFFSET = 24;

import Label from './Label.vue'

export default {
  name: 'FSide',
  props: {
    side: Object,
    length: {
      type: Number,
      default: 1500,
    },
    angle: {
      type: Number,
      default: null,
    },
    isFirst: {
      type: Boolean,
      default: true,
    },
    nextSideLength: {
      type: Number,
      default: 0,
    },
  },
  components: {
    FEdge,
    FNode,
    Label,
  },
  computed: {
    ...mapState({
      productDetails: state => state.productDetails,
      sides: state => state.sides,
    }),
    mitStaplerbarriere() {
      return this.productDetails && this.productDetails.key == 'mit-staplerbarriere'
    },
    numberOfParts() {
      return Math.ceil(this.length / LENGTH_MM);
    },
    partLength() {
      return LENGTH + SIDE_OFFSET;
    },
    lastPartLengthMm() {
      let mod = this.length % LENGTH_MM;
      if(mod === 0) {
        return LENGTH_MM;
      }
      return mod;
    },
    lastPartLength() {
      return this.lastPartLengthMm * LENGTH / LENGTH_MM + SIDE_OFFSET
    }
  }

}
</script>

<style scoped>
</style>
