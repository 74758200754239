<template>
  <v-card
    hover
    style="height: 100%"
  >
    <v-card-title>
      {{ group.name }}
    </v-card-title>
    <v-img
      :src="publicPath + group.image"
      height="150"
      contain
    ></v-img>
  </v-card>
</template>

<script>
export default {
  name: 'AdditionalProductsGroup',
  components: {
  },
  props: {
    group: Object,
  },
  computed: {
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
    }
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped>
  .amount{
    width: 120px;
  }
  .v-card__text, .v-card__title {
    word-break: normal !important;
  }
</style>
