<template>
  <g>
    <text :x="-64" :y="44" v-if="angle && angle2 < 180 && showAngle">
      {{ angle1 }}°
    </text>
    <text :x="-64" :y="-44" v-if="angle && angle2 >= 180 && showAngle">
      {{ angle2 }}°
    </text>
  </g>
</template>

<script>
export default {
  name: 'Label',
  props: {
    angle1: {
      type: Number,
      default: 0
    },
    angle2: {
      type: Number,
      default: 0,
    },
    showAngle: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    angle() {
      if(!this.angle1 || !this.angle2) {
        return null;
      }
      let max = Math.max(this.angle1, this.angle2);
      let min = Math.min(this.angle1, this.angle2);
      return max - min;
    }
  }

}
</script>

<style scoped>
</style>
