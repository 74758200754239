var render = function render(){var _vm=this,_c=_vm._self._c;return _c('g',[_c('path',{staticStyle:{"fill":"none","stroke-miterlimit":"1.6","stroke":"#f07e1a"},attrs:{"d":'M0,0' +
    'L' + _vm.width1 + ',0' +
    'L' + _vm.width1 + ',' + _vm.height1 +
    'L0,' + _vm.height1 + 
    'L0,0',"transform":'translate(' + _vm.offset1 + ' -' + _vm.height1/2 + ')'}}),(_vm.mitStaplerbarriere)?_c('path',{staticStyle:{"fill":"none","stroke-miterlimit":"0.5","stroke":"#e52521"},attrs:{"d":'M0,0' +
    'L' + _vm.width2 + ',0' +
    'L' + _vm.width2 + ',' + _vm.height2 +
    'L0,' + _vm.height2 + 
    'L0,0',"transform":'translate(' + _vm.offset2 + ' -' + _vm.height2/2 + ')'}}):_vm._e(),_c('Label',{attrs:{"transform":'translate(' + (_vm.offset1 + (_vm.isFirstSideElement ? 0 : -10)) + ' 0)',"length":_vm.width1 + (_vm.isFirstSideElement ? 0 : 10),"lengthMm":_vm.lengthMm,"originalLengthMm":_vm.originalLengthMm,"type":'edge',"isFirstSideElement":_vm.isFirstSideElement,"isLastSideElement":_vm.isLastSideElement,"hasSideLabel":_vm.hasSideLabel}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }