<template>
  <g>
    <!--<circle cx="0" cy="0" r="2"/>-->
    <circle cx="0" cy="0" r="72.5" stroke="black" stroke-width="1" fill="none" />
    <!--<path class="pfosten" style="fill:none;stroke:#010101;stroke-miterlimit:1.6;" d="M23.92,0a23.92,23.92,0,1,0-23.92,23.92,23.92,23.92,0,0,0,23.92-23.92"/>-->
    <WNode v-if="angle1 !== null && mitStaplerbarriere1" :transform="'rotate(' + angle1 + ' 0 0) translate(150.5 0)'" :angle1="0"/>
    <WNode v-if="angle2 !== null && mitStaplerbarriere2" :transform="'rotate(' + angle2 + ' 0 0) translate(150.5 0)'" :angle1="0"/>
    <LabelAngle :angle1="angle1" :angle2="angle2" />
  </g>
</template>

<script>
import { mapState } from 'vuex'

import LabelAngle from './LabelAngle.vue'
import WNode from './WNode.vue'

export default {
  name: 'FPfosten',
  props: {
    angle1: {
      type: Number,
      default: 0,
    },
    angle2: {
      type: Number,
      default: null
    },
    mitStaplerbarriere1: {
      type: Boolean,
      default: false,
    },
    mitStaplerbarriere2: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    LabelAngle,
    WNode,
  },
  computed: {
    ...mapState({
      productDetails: state => state.productDetails,
      sides: state => state.sides,
    }),
  }

}
</script>

<style scoped>
</style>
