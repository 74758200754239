<template>
  <g>
    <!--<circle cx="0" cy="0" r="2"/>-->
    <g v-if="angle2 === null" :transform="'rotate(' + (angle1 + 90) + ' 0 0) translate(-66.874 -77.076)'">
      <path
         id="path66"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 8.564,0.5 H 125.3" />
      <path
         id="path68"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 8.948,0.5 A 8.448,8.448 0 0 0 0.5,8.948" />
      <path
         id="path70"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 133.748,8.948 v 135.68" />
      <path
         id="path72"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 125.3,153.076 a 8.448,8.448 0 0 0 8.448,-8.448" />
      <path
         id="path74"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 133.748,8.948 A 8.448,8.448 0 0 0 125.3,0.5" />
      <path
         id="path76"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 125.428,153.076 H 8.692" />
      <path
         id="path78"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 0.5,144.628 a 8.448,8.448 0 0 0 8.448,8.448" />
      <path
         id="path80"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 0.5,144.628 V 8.948" />
      <path
         id="path82"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 14.324,146.932 h 105.6" />
      <path
         id="path84"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 128.5,32.756 h -8.704" />
      <path
         id="path86"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 119.796,32.756 v 97.28" />
      <path
         id="path88"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 128.244,33.78 v 105.6" />
      <path
         id="path90"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 119.796,146.932 a 8.448,8.448 0 0 0 8.448,-8.448" />
      <path
         id="path92"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 5.876,139.38 V 33.78" />
      <path
         id="path94"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 5.876,138.484 a 8.448,8.448 0 0 0 8.448,8.448" />
      <path
         id="path96"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 5.62,32.756 h 8.704" />
      <path
         id="path98"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 14.324,32.756 v 97.28" />
      <path
         id="path100"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 14.324,129.78 a 8.448,8.448 0 0 0 8.448,8.448" />
      <path
         id="path102"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 22.516,138.228 h 89.088" />
      <path
         id="path104"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 111.348,138.228 a 8.448,8.448 0 0 0 8.448,-8.448" />
      <path
         id="path106"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 77.812,48.372 A 10.496,10.496 0 1 0 67.316,58.868 10.496,10.496 0 0 0 77.812,48.372" />
      <path
         id="path108"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 77.812,105.204 A 10.496,10.496 0 1 0 67.316,115.7 10.496,10.496 0 0 0 77.812,105.204" />
    </g>

    <g v-if="angle2 == 180" :transform="'rotate(' + (angle1 + 90) + ' 0 0) translate(-93.7 -146.8345)'">
      <path
     id="path116"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="M 11.078,293.072 H 149.753" />
  <path
     id="path118"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="m 0.5,282.59075 a 10.578,10.578 0 0 0 10.578,10.578" />
  <path
     id="path120"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="M 160.46,282.59075 V 10.562" />
  <path
     id="path122"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="M 160.46,11.078 A 10.578,10.578 0 0 0 149.882,0.5" />
  <path
     id="path124"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="M 149.882,293.072 A 10.578,10.578 0 0 0 160.46,282.59075" />
  <path
     id="path126"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="M 150.011,0.5 H 10.14275" />
  <path
     id="path128"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="M 11.078,0.5 A 10.578,10.578 0 0 0 0.5,11.078" />
  <path
     id="path130"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="M 0.5,10.82 V 282.752" />
  <path
     id="path132"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="m 32.492,31.976 h 8.256" />
  <path
     id="path134"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="m 41.264,31.976 v 98.04" />
  <path
     id="path136"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="M 32.492,31.976 V 138.401" />
  <path
     id="path138"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="M 40.748,146.786 H 147.56" />
  <path
     id="path140"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="m 32.492,138.014 a 8.514,8.514 0 0 0 8.514,8.514" />
  <path
     id="path142"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="M 155.816,138.272 V 31.847" />
  <path
     id="path144"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="m 147.302,146.528 a 8.514,8.514 0 0 0 8.514,-8.514" />
  <path
     id="path146"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="M 155.816,31.976 H 147.56" />
  <path
     id="path148"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="m 147.56,31.976 v 98.04" />
  <path
     id="path150"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="m 139.046,138.272 a 8.514,8.514 0 0 0 8.514,-8.514" />
  <path
     id="path152"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="M 139.046,138.272 H 49.778" />
  <path
     id="path154"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="m 41.264,129.758 a 8.514,8.514 0 0 0 8.514,8.514" />
  <path
     id="path156"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="M 155.816,262.112 H 147.56" />
  <path
     id="path158"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="m 147.01175,261.596 v -98.04" />
  <path
     id="path160"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="M 155.816,262.112 V 155.3" />
  <path
     id="path162"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="M 147.56,146.528 H 40.748" />
  <path
     id="path164"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="m 155.816,155.3 a 8.514,8.514 0 0 0 -8.514,-8.514" />
  <path
     id="path166"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="M 32.492,155.3 V 262.112" />
  <path
     id="path168"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="M 41.006,146.786 A 8.514,8.514 0 0 0 32.492,155.3" />
  <path
     id="path170"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="m 32.492,262.112 h 8.256" />
  <path
     id="path172"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="m 41.264,261.596 v -98.04" />
  <path
     id="path174"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="m 49.778,155.3 a 8.514,8.514 0 0 0 -8.514,8.514" />
  <path
     id="path176"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="m 49.52,155.3 h 89.268" />
  <path
     id="path178"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="m 147.01175,163.814 a 8.514,8.514 0 0 0 -8.514,-8.514" />
  <path
     id="path180"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="m 101.636,223.67 a 10.578,10.578 0 1 0 -10.578,10.54575 10.578,10.578 0 0 0 10.578,-10.578" />
  <path
     id="path182"
     style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
     d="m 101.636,68.87 a 10.578,10.578 0 1 0 -10.578,10.54575 10.578,10.578 0 0 0 10.578,-10.578" />
    </g>

    <g v-if="angle2 == 90 || angle2 == 270" :transform="'rotate(' + (angle1 + (angle2 == 270 ? 90 : 0)) + ' 0 0) ' + (angle2 == 270 ? 'translate(-66 -77)' : 'translate(-196 -206.3935)')">
      <path
         id="path184"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 132.834,129.786 V 31.742" />
      <path
         id="path186"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 127.5,31.996 V 137.152" />
      <g
         id="g188"
         style="stroke-width:0.314961;stroke-miterlimit:1.6;stroke-dasharray:none"
         transform="matrix(3.175,0,0,3.175,-1.0875,-1.0875)">
         <path
            id="path190"
            style="fill:none;stroke:#e52521;stroke-width:0.314961;stroke-miterlimit:1.6;stroke-dasharray:none"
            d="m 37.81,46.28 a 2.64,2.64 0 0 0 2.64,-2.64"
            transform="translate(0.05,-0.1)" />
      </g>
      <path
         id="path192"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 119.118,145.534 H 14.343" />
      <path
         id="path194"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 127.5,31.996 V 137.152" />
      <path
         id="path196"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 132.834,129.786 A 10.414,10.414 0 0 0 143.248,140.2" />
      <path
         id="path198"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 14.343,150.8045 A 113.4745,113.4745 0 0 0 122.4835,258.945" />
      <path
         id="path200"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 77.208,92.702 A 10.414,10.414 0 1 0 66.794,103.116 10.414,10.414 0 0 0 77.208,92.702" />
      <path
         id="path202"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 118.864,31.996 H 132.58" />
      <path
         id="path204"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 132.834,31.742 V 10.914" />
      <path
         id="path206"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 132.834,10.914 A 10.414,10.414 0 0 0 122.42,0.5" />
      <path
         id="path208"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 122.42,0.5 H 10.914" />
      <path
         id="path210"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 10.914,0.5 A 10.414,10.414 0 0 0 0.5,10.914" />
      <path
         id="path212"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 0.5,10.914 V 31.996" />
      <path
         id="path214"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 0.5,31.996 H 14.216" />
      <path
         id="path216"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 14.216,31.996 v 97.028" />
      <path
         id="path218"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 14.216,128.77 a 8.382,8.382 0 0 0 8.382,8.382" />
      <path
         id="path220"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 22.344,137.152 h 88.392" />
      <path
         id="path222"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 110.736,137.152 a 8.382,8.382 0 0 0 8.382,-8.382" />
      <path
         id="path224"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 119.118,128.77 V 31.742" />
      <path
         id="path226"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 127.754,259.326 V 154.17" />
      <path
         id="path228"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 136.136,145.788 a 8.382,8.382 0 0 0 -8.382,8.382" />
      <path
         id="path230"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 240.784,140.2 H 143.248" />
      <path
         id="path232"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 136.136,145.788 H 240.911" />
      <path
         id="path234"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 240.784,140.2 v 13.716" />
      <path
         id="path236"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 240.784,153.916 h -96.52" />
      <path
         id="path238"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 144.518,153.916 a 8.382,8.382 0 0 0 -8.382,8.382" />
      <path
         id="path240"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 136.136,161.75825 v 88.9" />
      <path
         id="path242"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 136.136,250.69 a 8.382,8.382 0 0 0 8.382,8.255" />
      <path
         id="path244"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 144.264,258.945 h 96.52" />
      <path
         id="path246"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 240.784,258.945 v 13.716" />
      <path
         id="path248"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 240.784,272.788 h 20.828" />
      <path
         id="path250"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 261.58025,272.788 a 10.414,10.414 0 0 0 10.541,-10.414" />
      <path
         id="path252"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 272.12125,262.374 V 150.614" />
      <path
         id="path254"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 272.12125,150.614 A 10.414,10.414 0 0 0 261.70725,140.2" />
      <path
         id="path256"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 261.612,140.2 H 240.784" />
      <path
         id="path258"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 190.492,206.494 a 10.414,10.414 0 1 0 -10.44575,10.414 10.414,10.414 0 0 0 10.414,-10.414" />
      <path
         id="path266"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-linecap:round;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 127.5,258.945 v 0" />
      <path
         id="path270"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 127.754,259.326 a 8.382,8.382 0 0 0 8.382,8.382" />
      <path
         id="path274"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 132.58,272.788 h 3.556" />
      <path
         id="path276"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-linecap:round;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 14.216,145.788 v 0" />
      <path
         id="path278"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-linecap:round;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 127.5,258.945 v 0" />
      <path
         id="path280"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 14.216,145.3435 A 113.538,113.538 0 0 0 127.84925,258.945" />
      <path
         id="path282"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 0.5,137.152 v 3.175" />
      <path
         id="path288"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 0.5,31.996 V 137.152" />
      <path
         id="path296"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-linecap:round;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 14.216,145.788 v 0" />
      <path
         id="path302"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 136.136,250.69 a 8.382,8.382 0 0 0 8.382,8.255" />
      <path
         id="path304"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 14.216,128.77 a 8.382,8.382 0 0 0 8.382,8.382" />
      <path
         id="path310"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 119.118,128.77 V 31.742" />
      <path
         id="path314"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 136.136,272.788 H 240.911" />
      <path
         id="path316"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 240.784,267.708 H 136.009" />
      <path
         id="path328"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 22.344,137.152 h 88.392" />
      <path
         id="path330"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 118.864,31.996 H 132.58" />
      <path
         id="path356"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 132.58,272.788 h 3.556" />
      <path
         id="path360"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 0.5,137.152 v 3.175" />
      <path
         id="path374"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 261.612,140.2 H 240.784" />
      <path
         id="path380"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 6.088,32.53575 v 104.775" />
      <g
         id="g382"
         style="stroke-width:0.314961;stroke-miterlimit:1.6;stroke-dasharray:none"
         transform="matrix(3.175,0,0,3.175,-1.0875,-1.0875)">
         <path
            id="path384"
            style="fill:none;stroke:#e52521;stroke-width:0.314961;stroke-miterlimit:1.6;stroke-dasharray:none"
            d="m 2.21,43.64 a 2.64,2.64 0 0 0 2.64,2.64"
            transform="translate(0.05,-0.1)" />
      </g>
      <path
         id="path280-2"
         data-name="path280"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 0.5,140.2 c 0,35.687 9.779,72.23125 33.909,92.74175 21.30425,21.336 54.864,37.56025 98.171,39.84625" />

   </g>
   <g v-if="angle2 == 45 || angle2 == 315" :transform="'rotate(' + (angle1 + 90 + (angle2 == 315 ? 135 : 0)) + ' 0 0) ' + (angle2 == 45 ? 'translate(-167.951 -78.6)' : 'translate(-103 -234)')">
      <path
         id="path388"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 229.36501,32.244 v 105.6" />
      <g
         id="g390"
         transform="matrix(3.1999965,0,0,3.2,-1.0987342,-1.1000003)"
         style="stroke-width:0.3125;stroke-miterlimit:1.6;stroke-dasharray:none">
         <path
            id="path392"
            style="fill:none;stroke:#e52521;stroke-width:0.3125;stroke-miterlimit:1.6;stroke-dasharray:none"
            d="m 329.05,412.74 a 2.64,2.64 0 0 0 2.64,-2.64"
            transform="translate(-259.67,-366.72)" />
      </g>
      <path
         id="path394"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 239.093,150.196 a 8.1279912,8.128 0 0 0 0.768,-3.456" />
      <path
         id="path396"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 239.957,10.996 v 135.68" />
      <path
         id="path398"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 239.957,10.996 A 10.495989,10.496 0 0 0 229.46101,0.49999973" />
      <path
         id="path400"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 106.48514,0.49999973 H 229.36501" />
      <path
         id="path402"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 106.74114,0.49999973 A 10.495989,10.496 0 0 0 96.24516,10.996" />
      <path
         id="path404"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 96.24516,136.18 V 10.74" />
      <path
         id="path406"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 3.1572611,229.364 96.34116,136.18" />
      <path
         id="path408"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 3.5732607,228.852 a 10.495989,10.496 0 0 0 0,14.848" />
      <path
         id="path410"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 90.613166,330.74 3.5732607,243.7" />
      <path
         id="path412"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 90.613166,330.74 a 10.463989,10.464 0 0 0 14.815984,0" />
      <path
         id="path414"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 201.17304,234.484 105.55715,330.74" />
      <path
         id="path416"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 201.17304,234.484 a 9.8239894,9.824 0 0 0 2.176,-3.2" />
      <path
         id="path418"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 239.189,149.908 -35.83996,81.408" />
      <path
         id="path420"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 180.21306,70.9 A 10.495989,10.496 0 1 0 169.71708,81.396 10.495989,10.496 0 0 0 180.21306,70.9" />
      <path
         id="path422"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 108.53314,239.796 A 10.495989,10.496 0 1 0 98.037158,250.292 10.495989,10.496 0 0 0 108.53314,239.796" />
      <path
         id="path424"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 106.48514,302.58 68.60793,-68.608" />
      <path
         id="path426"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 175.09307,233.972 c 3.488,-3.456 5.21599,-7.2 2.112,-10.304" />
      <path
         id="path428"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 177.20507,223.796 -63.99993,-64" />
      <path
         id="path430"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 113.10914,159.796 a 8.5439907,8.544 0 0 0 -5.92,-3.424 7.9039914,7.904 0 0 0 -6.84799,2.848" />
      <path
         id="path432"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 100.34115,159.22 31.73323,227.828" />
      <path
         id="path434"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 31.73323,227.828 -6.175993,-6.144" />
      <path
         id="path436"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 25.557237,221.684 100.34115,146.996" />
      <path
         id="path438"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 114.16514,148.468 c -5.12,-5.248 -9.91999,-4.896 -13.98399,-1.376" />
      <path
         id="path440"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 106.99714,137.972 c 0,6.944 2.624,8.352 10.23999,8.192" />
      <path
         id="path442"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 106.99714,137.972 V 31.988" />
      <path
         id="path444"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 106.48514,32.244 h 8.672" />
      <path
         id="path446"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 115.70113,31.988 v 97.28" />
      <path
         id="path448"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 115.70113,129.268 a 8.4479908,8.448 0 0 0 8.448,8.448" />
      <path
         id="path450"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 123.89313,137.716 h 88.5759" />
      <path
         id="path452"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 212.46903,137.716 a 8.4479908,8.448 0 0 0 8.44799,-8.448" />
      <path
         id="path454"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 220.91702,129.268 V 31.988" />
      <g
         id="g456"
         transform="matrix(3.1999965,0,0,3.2,-1.0987342,-1.1000003)"
         style="stroke-width:0.3125;stroke-miterlimit:1.6;stroke-dasharray:none">
         <path
            id="path458"
            style="fill:none;stroke:#e52521;stroke-width:0.3125;stroke-miterlimit:1.6;stroke-dasharray:none"
            d="m 318.66,440.08 a 2.65,2.65 0 0 0 0,-3.74"
            transform="translate(-259.67,-366.72)" />
      </g>
      <path
         id="path460"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 187.89306,233.396 -75.26392,75.36" />
      <path
         id="path468"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 188.91706,223.22 -1.536,-1.536" />
      <g
         id="g470"
         transform="matrix(3.1999965,0,0,3.2,-1.0987342,-1.1000003)"
         style="stroke-width:0.3125;stroke-miterlimit:1.6;stroke-dasharray:none">
         <path
            id="path472"
            style="fill:none;stroke:#e52521;stroke-width:0.3125;stroke-miterlimit:1.6;stroke-dasharray:none"
            d="m 319.12,436.9 a 1.46,1.46 0 0 0 0.61,0.36"
            transform="translate(-259.67,-366.72)" />
      </g>
      <path
         id="path478"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 226.80501,152.052 -31.23196,70.144" />
      <g
         id="g480"
         transform="matrix(3.1999965,0,0,3.2,-1.0987342,-1.1000003)"
         style="stroke-width:0.3125;stroke-miterlimit:1.6;stroke-dasharray:none">
         <path
            id="path482"
            style="fill:none;stroke:#e52521;stroke-width:0.3125;stroke-miterlimit:1.6;stroke-dasharray:none"
            d="m 319.56,437.21 a 1.36,1.36 0 0 0 1.59,-0.76"
            transform="translate(-259.67,-366.72)" />
      </g>
      <path
         id="path490"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 226.80501,152.052 -31.23196,70.144" />
      <g
         id="g506"
         transform="matrix(3.1999965,0,0,3.2,-1.0987342,-1.1000003)"
         style="stroke-width:0.3125;stroke-miterlimit:1.6;stroke-dasharray:none">
         <path
            id="path508"
            style="fill:none;stroke:#e52521;stroke-width:0.3125;stroke-miterlimit:1.6;stroke-dasharray:none"
            d="m 319.12,436.9 a 1.46,1.46 0 0 0 0.61,0.36"
            transform="translate(-259.67,-366.72)" />
      </g>
      <path
         id="path510"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 188.91706,223.22 -1.536,-1.536" />
      <path
         id="path562"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-linecap:round;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 96.24516,136.18 v 0" />
      <path
         id="path568"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 175.60507,233.556" />
      <path
         id="path568-2"
         data-name="path568"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 106.99714,302.164" />
      <path
         id="path570"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 106.48514,32.244 h 8.672" />
      <path
         id="path572"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 115.70113,31.988 v 97.28" />
      <path
         id="path574"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 229.36501,32.244 v 105.6" />
      <path
         id="path582"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 114.16514,148.468 72.70392,72.704" />
      <path
         id="path584"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 186.86906,221.172 220.14902,146.42" />
      <path
         id="path586"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="M 220.91702,146.164 H 117.23713" />
      <path
         id="path590"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-linecap:round;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 96.24516,136.18 v 0" />
      <path
         id="path606"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 123.89313,137.716 h 88.5759" />
      <path
         id="path608"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 115.70113,129.268 a 8.4479908,8.448 0 0 0 8.448,8.448" />
      <path
         id="path628"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 114.16514,148.468 73.21592,73.216" />
      <path
         id="path646"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 112.62914,308.724 -6.144,-6.144" />
      <path
         id="path648"
         style="fill:none;stroke:#e52521;stroke-width:1;stroke-miterlimit:1.6;stroke-dasharray:none"
         d="m 229.36501,32.244 h -8.19199" />
      <g
         id="g390-2"
         data-name="g390"
         transform="matrix(3.1999965,0,0,3.2,-1.0987342,-1.1000003)"
         style="stroke-width:0.3125;stroke-miterlimit:1.6;stroke-dasharray:none">
         <path
            id="path392-2"
            data-name="path392"
            style="fill:none;stroke:#e52521;stroke-width:0.3125;stroke-miterlimit:1.6;stroke-dasharray:none"
            d="m 330.89,414.58 c 0.2,-0.45 0.29,-1.89 -1.84,-1.84"
            transform="translate(-259.67,-366.72)" />
      </g>
   </g>

    <LabelAngle :angle1="angle1" :angle2="angle2" />
  </g>
</template>

<script>
import { mapState } from 'vuex'

import LabelAngle from './LabelAngle.vue'

export default {
  name: 'WNode',
  props: {
    angle1: {
      type: Number,
      default: 0,
    },
    angle2: {
      type: Number,
      default: null
    }
  },
  components: {
    LabelAngle,
  },
  computed: {
    ...mapState({
      sides: state => state.sides,
    }),
  }

}
</script>

<style scoped>
</style>
