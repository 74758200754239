<template>
  <div>
    <ProductInfos
      :image-only="true"
      style="max-width:180px;max-height:55px;position:absolute;right:20px;cursor:pointer"
      @click.native="setPresentationTab(0)"
    />
    <div ref="outer" class="text-center">
      <v-toolbar dense floating collapse flat style="max-width:unset!important;margin:20px" >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="setRotation(-90)"
              v-bind="attrs"
              v-on="on">
              <v-icon>mdi-rotate-left</v-icon>
            </v-btn>
          </template>
          <span>Rotieren links</span>
        </v-tooltip>
        <v-btn
          style="min-width:0;padding:0;margin:0;"
          disabled
          plain>
          {{rotate}}°
        </v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="setRotation(+90)"
              v-bind="attrs"
              v-on="on">
              <v-icon>mdi-rotate-right</v-icon>
            </v-btn>
          </template>
          <span>Rotieren rechts</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="zoomOut"
              v-bind="attrs"
              v-on="on">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </template>
          <span>Verkleinern</span>
        </v-tooltip>
        <v-btn
          style="min-width:0;padding:0;margin:0;text-transform:unset"
          disabled
          plain>
          {{ scale.toFixed(1).replace('.', ',') }}x
        </v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="zoomIn"
              v-bind="attrs"
              v-on="on">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Vergrößern</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="fit"
              v-bind="attrs"
              v-on="on">
              <v-icon>mdi-arrow-collapse-all</v-icon>
            </v-btn>
          </template>
          <span>Einpassen</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="print"
              v-bind="attrs"
              v-on="on">
              <v-icon>mdi-printer</v-icon>
            </v-btn>
          </template>
          <span>Drucken</span>
        </v-tooltip>
      </v-toolbar>
    </div>
    <svg class="svg" ref="svg" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" :viewPort="'0 0 ' + outerWidth + ' ' + svgInnerHeight" :style="'stroke-width:3px;width:' + outerWidth + 'px;height:' + svgInnerHeight + 'px;min-height:' + minHeight" >
      <g ref="svgInner">
        <g :transform="'rotate(' + rotate + ' 0 0)'">
          <g :transform="'scale(0.1 0.1)'">
            <g v-for="(side, i) in sides" :key="side.id">
              <template v-if="side.type=='side'">
                <FSide
                  :side="side"
                  :transform="side.transform"
                  :length="side.length"
                  :angle="!side.is_last || shaping.key=='shaping-o' ? side.angle : null"
                  :is-first="side.is_first && shaping.key != 'shaping-o'"
                  :nextSideLength="(i+1 < sides.length) ? sides[i+1].length : (shaping.key=='shaping-o' ? sides[0].length : 0)"
                  v-if="product && product.key === 'product-fussgaengerschutz'" 
                />
                <PSide
                  :side="side"
                  :transform="side.transform"
                  :length="side.length"
                  :angle="!side.is_last ? side.angle : null"
                  :is-first="side.is_first"
                  v-if="product && (product.key === 'product-palettenspeicher' || product.key === 'product-guardrail')"
                />
                <WSide
                  :side="side"
                  :transform="side.transform"
                  :length="side.length"
                  :angle="!side.is_last || shaping.key=='shaping-o' ? side.angle : null"
                  :is-first="side.is_first && shaping.key != 'shaping-o'"
                  v-if="product && product.key === 'product-wandschutz'"
                />
              </template>
              <Interrupt
                v-else
                :transform="side.transform"
                :length="side.length"
              />
            </g>
          </g>
        </g>
      </g>        
    </svg>
    <small><i>Mit * markierte Seiten müssen selbst zugeschnitten werden.</i></small>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

import {EasyPZ} from 'easypz'


import FSide from './drawings/FSide.vue'
import {LENGTH_FACTOR as FLENGTH_FACTOR} from './drawings/FSide.vue'
import PSide from './drawings/PSide.vue'
import {LENGTH_FACTOR as PLENGTH_FACTOR} from './drawings/PSide.vue'
import WSide from './drawings/WSide.vue'
import {LENGTH_FACTOR as WLENGTH_FACTOR} from './drawings/WSide.vue'

import Interrupt from './drawings/Interrupt.vue'
import ProductInfos from './ProductInfos.vue'


export default {
  name: 'Drawing',
  props: {
  },
  components: {
    FSide,
    Interrupt,
    PSide,
    ProductInfos,
    WSide,
  },
  data () {
    return {
      easyPz: null,
      rotate: 0,
      svgOuterWidth: 0,
      svgOuterHeight: 0,
      svgInnerWidth: 0,
      svgInnerHeight: 0,
      outerWidth: 0,
      minHeight: '500px',
      tab: 1,
    }
  },  
  computed: {
    ...mapState({
      guideStep: state => state.guideStep,
      product: state => state.product,
      originalSides: state => state.sides,
      shaping: state => state.shaping,
    }),
    ...mapGetters({
      sides: 'computedSides',
    }),
    lenghtFactor() { // should be 1...
      if(!this.product) {
        return 0;
      }
      switch(this.product.key) {
        case 'product-fussgaengerschutz':
          return FLENGTH_FACTOR;
        case 'product-guardrail':
        case 'product-palettenspeicher':
          return PLENGTH_FACTOR;
        case 'product-wandschutz':
          return WLENGTH_FACTOR;
        default:
          return 0;
      }
    },
    scale() {
      if(!this.easyPz || !this.easyPz.totalTransform) { 
        return 0;
      }
      return this.easyPz.totalTransform.scale;
    },
   
  },
  mounted() {
    this.easyPz = new EasyPZ(
      this.$refs.svg,
      function() {},
      {
        minScale: 0.2,
        maxScale: 10,
        bounds: {
          top: -100000,
          right: 100000,
          bottom: 100000,
          left: -100000,
        },
      },
      null,
      {},
      function() {},
      function() {},
      function() {},
      "svg > g"
    );
    setInterval(() => {
      this.captureState();
    }, 1000);
    this.$root.$on('fitDrawing', () => {
      this.rotate = 0;
      setTimeout(() => {
        let origMinHeight = this.minHeight;
        this.minHeight = 'unset';
        this.fit();
        this.captureState();
        setTimeout(() => {
          this.captureState();
          this.$root.$emit('fitDrawingDone')
          this.minHeight = origMinHeight;
        }, 300);
      }, 100);
    });

    this.fit()
    this.captureState();
    this.fit()
  },
  watch: {
    shaping () {
      setTimeout(() => this.captureStateAndFit(), 10);
    },
    sides () {
      setTimeout(() => this.captureStateAndFit(), 10);
    }
  },
  methods: {
    ...mapMutations([
      'setDrawingSvg',
      'setGuideTab',
      'setPresentationTab',
    ]),

    captureStateAndFit() {
      this.captureState();
      this.fit()
      this.captureState();
      this.fit()
    },
    captureState() {
      if(this.$refs.outer) {
        this.outerWidth = this.$refs.outer.getBoundingClientRect().width;
      }
      if(this.$refs.svg) {
        this.setDrawingSvg({
          svg: this.$refs.svg.outerHTML,
          width: this.$refs.svg.getBoundingClientRect().width,
          height: this.$refs.svg.getBoundingClientRect().height
        });
      }
      if(this.$refs.svg) {
        this.svgOuterWidth  = this.$refs.svg.getBoundingClientRect().width;
        this.svgOuterHeight = this.$refs.svg.getBoundingClientRect().height;
      }
      if(this.$refs.svgInner) {
        this.svgInnerWidth = this.$refs.svgInner.getBoundingClientRect().width;
        this.svgInnerHeight = this.$refs.svgInner.getBoundingClientRect().height;
      }
    },
    setRotation(degree) {
      this.rotate = (this.rotate + degree) % 360;
      this.fit();
      setTimeout(() => { this.fit() }, 100);
    },
    fit() {
      if(!this.$refs.svg || !this.$refs.svgInner) {
        console.warn('Skip fitting - elements not found');
        return;
      }
      // wait until svg is visible to calculate .getBoundingClientRect().width correctly
      setTimeout(() => {
      this.easyPz.totalTransform.translateX = 0;
      this.easyPz.totalTransform.translateY = 0;
      this.easyPz.totalTransform.scale = 1;
      this.easyPz.applyTransformation();

      let scale = Math.min(
        this.$refs.svg.getBoundingClientRect().width/this.$refs.svgInner.getBoundingClientRect().width,
        1000/this.$refs.svgInner.getBoundingClientRect().height
      )
      let diffLeft  = (this.$refs.svg.getBoundingClientRect().left - this.$refs.svgInner.getBoundingClientRect().left) / 1;
      let diffTop   = (this.$refs.svg.getBoundingClientRect().top - this.$refs.svgInner.getBoundingClientRect().top) / 1;
      this.easyPz.totalTransform.translateX = diffLeft;
      this.easyPz.totalTransform.translateY = diffTop;
      if(isNaN(scale) || !isFinite(scale) || scale == 0) {
        console.warn('Failed to calculate scale - setting scale to 1.')
        scale = 1;
      }
      this.easyPz.totalTransform.scale = scale;
      
      this.easyPz.applyTransformation();

      let diffWidth = (this.$refs.svg.getBoundingClientRect().width - this.$refs.svgInner.getBoundingClientRect().width)/scale
      this.easyPz.totalTransform.translateX = diffLeft + diffWidth/2;
      this.easyPz.applyTransformation();
      }, 100);
    },
    print() {
      this.setGuideTab(2)
    },
    zoomIn() {
      this.easyPz.totalTransform.scale *=1.1;
      this.easyPz.applyTransformation();
    },
    zoomOut() {
      this.easyPz.totalTransform.scale /=1.1;
      this.easyPz.applyTransformation();
    }
  }

}
</script>

<style scoped>
</style>

