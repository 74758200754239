import {default as ARTICLES} from './fussgaengerschutz.articles';

function getPartListFussgaengerschutz(fussbodenheizung, productDetails, shaping, side, prev, next) {

  if(side.type == 'interrupt') {
    return [];
  }

  let mitStaplerbarriere = productDetails.key == 'mit-staplerbarriere';
  let fischmaulArtNo = '';
  if (mitStaplerbarriere && fussbodenheizung) {
    fischmaulArtNo = ARTICLES.FISCHMAUL_FUSSBODENHEIZUNG_STAPLERBARRIERE;
  } else if (fussbodenheizung) {
    fischmaulArtNo = ARTICLES.FISCHMAUL_FUSSBODENHEIZUNG;
  } else {
    fischmaulArtNo = ARTICLES.FISCHMAUL_NORMAL;
  }
  let parts = [];
  if(side.is_first) {
    if(shaping.key == 'shaping-o') {
      parts.push(ARTICLES.ECKPFOSTEN);                               // 1x eckpfosten
      if(mitStaplerbarriere && side.length >= 400) {
        parts.push(fischmaulArtNo);                 // 1x Fischmaul
      }
      if(mitStaplerbarriere && next.length >= 400) { // next has same length as 4th side (rectangle)
        parts.push(fischmaulArtNo);                 // 1x Fischmaul
      }
    }else{
      parts.push(ARTICLES.ENDPFOSTEN);                               // 1x endpfosten
      if(mitStaplerbarriere && side.length >= 400) {
        parts.push(fischmaulArtNo);                 // 1x Fischmaul
      }
    }
  }
  let partLength     = 1500;
  let numberOfSteps  = Math.floor(side.length / partLength);
  let lastPartLength = side.length - (numberOfSteps) * partLength;
  for(let j = 0; j < numberOfSteps; j++) {
    parts.push(ARTICLES.HANDLAUF);                               // 3x Handlauf
    parts.push(ARTICLES.HANDLAUF);
    parts.push(ARTICLES.HANDLAUF);
    if(mitStaplerbarriere) {
      parts.push(ARTICLES.STAPLERBARRIERE);                             // 1x Staplerbarriere
    }
  }
  for(let j = 0; j < numberOfSteps - 1; j++) {
    parts.push(ARTICLES.MITTELPFOSTEN);                               // 1x Mittelpfosten
    if(mitStaplerbarriere) {
      parts.push(fischmaulArtNo);
      parts.push(fischmaulArtNo);
    }
  }

  if(lastPartLength > 0) {
    if(numberOfSteps > 0) {
      parts.push(ARTICLES.MITTELPFOSTEN);
      if(mitStaplerbarriere) {
        parts.push(fischmaulArtNo);
      }
    }
    for(let j = 0; j < 3; j++) {
      parts.push({
        'article_number': ARTICLES.HANDLAUF,
        'original_length': partLength,
        'length': lastPartLength,
      });
    }
    if(mitStaplerbarriere && lastPartLength >= 400) {
      if(numberOfSteps > 0) {
        parts.push(fischmaulArtNo); // staplerbarriere am letzten Teil ist nur bei length > 400 da
      }
      parts.push({
        'article_number': ARTICLES.STAPLERBARRIERE,
        'original_length': 1160,
        'length': lastPartLength - (partLength - 1160),
      });
    }
  }

  // ecke zu nächster seite
  if(side.angle && !side.is_last) {
    parts.push(ARTICLES.ECKPFOSTEN);                             // Eckpfosten
    if(mitStaplerbarriere && (lastPartLength >= 400 || lastPartLength === 0)) {
      parts.push(fischmaulArtNo);               // 2x Fischmaul
    }
    // mit staplerbarriere, wenn nächste Seite > 400
    if(mitStaplerbarriere && (next.length >= 400)) {
      parts.push(fischmaulArtNo);
    }
  }
  // endpfosten
  if(side.is_last && shaping.key != 'shaping-o') { // if shaping is rectangle we do not need an endpfosten - we use eckpfosten as first pfosten
    parts.push(ARTICLES.ENDPFOSTEN);                               // 1x endpfosten
    if(mitStaplerbarriere && (lastPartLength >= 400 || lastPartLength === 0)) {
      parts.push(fischmaulArtNo);                 // 1x Fischmaul
    }
  }
  return parts;
}

export default getPartListFussgaengerschutz;