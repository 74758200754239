<template>
  <v-expansion-panel-header
    :disable-icon-rotate="type !== 'expand'"
  >
    <template v-slot:default="{}">
      <v-row no-gutters>
        <v-col cols="6" class="text-left">
          {{ title }}
        </v-col>
        <v-col
          cols="4"
          class="text--secondary"
        >
          {{ subtitle }}
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-icon color="primary" v-if="type === 'expand'">
        $expand
      </v-icon>
      <v-icon color="teal" v-if="type === 'success'">
        mdi-check
      </v-icon>
    </template>
  </v-expansion-panel-header>
</template>

<script>
export default {
  name: 'Header',
  props: {
    title: String,
    subtitle: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'expand',
      validator: function (value) {
        return ['expand', 'success', 'danger'].indexOf(value) !== -1
      }
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style scoped>
</style>
