export default {
  ENDKONSOLE_AUFGEDOPPELT_FUSSBODENHEIZUNG: 353,
  ENDKONSOLE_AUFGEDOPPELT_NORMAL: 349,
  ENDKONSOLE_FLACH_FUSSBODENHEIZUNG: 352,
  ENDKONSOLE_FLACH_NORMAL: 348,
  MITTELKONSOLE_AUFGEDOPPELT_FUSSBODENHEIZUNG: 351,
  MITTELKONSOLE_AUFGEDOPPELT_NORMAL: 347,
  MITTELKONSOLE_FLACH_FUSSBODENHEIZUNG: 350,
  MITTELKONSOLE_FLACH_NORMAL: 346,
  ECKKONSOLE_AUFGEDOPPELT_FUSSBODENHEIZUNG: 821,
  ECKKONSOLE_AUFGEDOPPELT_NORMAL: 517,
  ECKKONSOLE_FLACH_FUSSBODENHEIZUNG: 819,
  ECKKONSOLE_FLACH_NORMAL: 543,
  ECKKONSOLE_AUFGEDOPPELT_FUSSBODENHEIZUNG_45: 825,
  ECKKONSOLE_AUFGEDOPPELT_NORMAL_45: 525,
  ECKKONSOLE_FLACH_FUSSBODENHEIZUNG_45: 822,
  ECKKONSOLE_FLACH_NORMAL_45: 520,
  BARRIERE_3000: 2222,
  BARRIERE_1500: 2223,
  BARRIERE_1160: 799,
  BARRIERE_2900: 2222,
  BARRIERE_1450: 2223
}