import {default as ARTICLES} from './palettenschutz.articles';

function getPartListFussgaengerschutz(fussbodenheizung, productDetails, shaping, side, prev, next) { // eslint-disable-line no-unused-vars

  if(side.type == 'interrupt') {
    return [];
  }

  let parts = [];
  let numberOfBarriers   = productDetails.barrier_amount;
  let endpfostenArtNo    = ARTICLES.ENDPFOSTEN;
  let eckpfostenArtNo    = ARTICLES.ECKPFOSTEN;
  let mittelpfostenArtNo = ARTICLES.MITTELPFOSTEN;
  if(side.is_first) {
    parts.push(endpfostenArtNo);
  }

  let partLength     = 2900;
  let numberOfSteps  = Math.floor(side.length / partLength);
  let lastPartLength = side.length - numberOfSteps * partLength;
  for(let j = 0; j < numberOfSteps * numberOfBarriers; j++) {
    parts.push(ARTICLES.BARRIERE);
  }
  for(let j = 0; j < numberOfSteps - 1; j++) {
    parts.push(mittelpfostenArtNo);
  }
  if(lastPartLength > 0) {
    if(numberOfSteps > 0) {
      parts.push(mittelpfostenArtNo);
    }
    let lastPartArticleNumber  = ARTICLES.BARRIERE;
    let lastPartOriginalLength = partLength;
    if(lastPartLength <= 1450) {
      lastPartArticleNumber = ARTICLES.BARRIERE_1500;
      lastPartOriginalLength = 1450;
    }
    for(let j = 0; j < numberOfBarriers; j++) {
      if(lastPartLength == 1450) {
        parts.push(lastPartArticleNumber)
      }else{
        parts.push({
          'article_number': lastPartArticleNumber,
          'original_length': lastPartOriginalLength,
          'length': lastPartLength,
        });
      }
    }
  }

  if(side.angle && !side.is_last) {
    if(side.angle == 90 || side.angle == 270) {
      parts.push(eckpfostenArtNo);
    }else{
      parts.push(endpfostenArtNo);
      parts.push(endpfostenArtNo);
    }
  }

  if(side.is_last) {
    parts.push(endpfostenArtNo);
  }
  return parts;
}

export default getPartListFussgaengerschutz;