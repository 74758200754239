<template>
  <div>
    <Header
      :subtitle="(selectedShaping) ? selectedShaping.name : ''"
      :title="'Formauswahl'"
      :type="(selectedShaping) ? 'success' : undefined"
    />
    <v-expansion-panel-content>
      <p>Welche Form ist für Ihr Vorhaben die Richtige? Falls keine unserer Standard Grundformen auf Ihr Projekt zutrifft, so wählen Sie bitte die Frei-Form. Hier können Sie Ihre individuelle Form von Grund auf konfigurieren.</p>
      <v-row>
        <v-col
          v-for="shaping in shapings"
          :key="shaping.key"
          sm="6"
          lg="4"
          xl="3"
        >
          <Card
            :image="publicPath + shaping.image"
            :title="shaping.name"
            :selected="selectedShaping && selectedShaping.key === shaping.key"
            :downloadButton="(shaping.cadFile) ? {text: 'CAD-Datei herunterladen', url: shaping.cadFile} : null"
            @click="updateShaping(shaping)"
          />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </div>
</template>

<script>
import Card from './parts/Card.vue'
import Header from './parts/Header.vue'
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'Shaping',
  components: {
    Card,
    Header,
  },
  props: {
  },
  computed: {
    ...mapGetters({
      isProductConfigured: 'isProductConfigured'
    }),
    ...mapState({
      selectedAnchoring: state => state.anchoring,
      selectedProduct: state => state.product,
      productDetails: state => state.productDetails,
      selectedShaping: state => state.shaping,
    }),
    productFilename() {
      if(!this.selectedProduct) return '';
      switch(this.selectedProduct.key) {
        case 'product-fussgaengerschutz':
          if(this.productDetails && this.productDetails.key === 'mit-staplerbarriere') {
            return 'fussgaengerschutz-mit-staplerbarriere';
          }else{
            return 'fussgaengerschutz-ohne-staplerbarriere';
          }
        case 'product-wandschutz':
          if(this.productDetails && this.productDetails.key === 'aufgedoppelt') {
            return 'wandschutz-aufgedoppelt';
          }else{
            return 'wandschutz-flach';
          }
        case 'product-guardrail':
          return 'guardrail';
        case 'product-palettenspeicher':
          return 'palettenspeicher';
        default:
          return '';
      }
    },
    cadFilename() {
      if(this.productFilename == 'guardrail') {
        // since guardrail is now palettenspeicher 900, we'll return the same files
        return 'palettenspeicher';
      }
      return this.productFilename
    },
    shapings() {
      let shapings = [
        {
          'key': 'shaping-i',
          'name': 'I-Form',
          'image': 'images/shapings/' + this.productFilename + '-i.jpg',
          'cadFile': 'cad/shapings/' + this.cadFilename + '-i.dwg',
        },
        {
          'key': 'shaping-l',
          'name': 'L-Form',
          'image': 'images/shapings/' + this.productFilename + '-l.jpg',
          'cadFile': 'cad/shapings/' + this.cadFilename + '-l.dwg',
        },
        {
          'key': 'shaping-u',
          'name': 'U-Form',
          'image': 'images/shapings/' + this.productFilename + '-u.jpg',
          'cadFile': 'cad/shapings/' + this.cadFilename + '-u.dwg',
        },
        {
          'key': 'shaping-z',
          'name': 'Z-Form',
          'image': 'images/shapings/' + this.productFilename + '-z.jpg',
          'cadFile': 'cad/shapings/' + this.cadFilename + '-z.dwg',
        },
      ]
      if(this.selectedProduct && ['product-wandschutz', 'product-fussgaengerschutz'].includes(this.selectedProduct.key)) {
        shapings.push({
          'key': 'shaping-o',
          'name': 'Geschlossene Form (Säulenschutz)',
          'image': 'images/shapings/' + this.productFilename + '-o.jpg',
          'cadFile': 'cad/shapings/' + this.cadFilename + '-o.dwg',
        })
      }

      shapings.push({
        'key': 'shaping-custom',
        'name': 'Freiform',
        'image': 'images/shapings/' + this.productFilename + '-custom.jpg',
        'cadFile': 'cad/shapings/' + this.cadFilename + '-custom.dwg',
      })

      return shapings
    }
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
    }
  },
  methods: {
    ...mapMutations([
      'setShaping',
      'setSides',
    ]),
    updateShaping(shaping) {
      if(!this.shaping) {
        // first time user choose a shaping
        this.updateSides(shaping);
      }
      if(this.shaping && this.shaping.key !== shaping.key) {
        // user already chosen a shaping - and now another one.
        // TODO should we ask him before overwriting current dimensions?
        this.updateSides(shaping);
      }
      this.setShaping(shaping);
    },
    updateSides(shaping) {
      let length = this.selectedProduct.defaultLength;
      switch(shaping.key){
        case 'shaping-i':
          this.setSides([
            this.newSide(length, 0),
          ]);
          break;
        case 'shaping-l':
          this.setSides([
            this.newSide(length, 90),
            this.newSide(length, 0),
          ]);
          break;
        case 'shaping-u':
          this.setSides([
            this.newSide(length, 90),
            this.newSide(length, 90),
            this.newSide(length, 0),
          ]);
          break;
        case 'shaping-z':
        case 'shaping-custom':
          this.setSides([
            this.newSide(length, 90),
            this.newSide(length, 270),
            this.newSide(length, 0),
          ]);
          break;
        case 'shaping-o':
          this.setSides([
            this.newSide(length, 90),
            this.newSide(length, 90),
            this.newSide(length, 90),
            this.newSide(length, 90),
          ]);
          break;
      }
    },
    newSide(length, angle) {
      return {
        type: 'side',
        length: length,
        angle: angle,
      }
    }
  },
}
</script>

<style scoped>
</style>
