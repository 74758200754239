<template>
  <div>
    <Header
      :subtitle="''"
      :title="'Abmaße'"
      :type="undefined"
    />
    <v-expansion-panel-content>
      <p v-if="isEditable">
        Bitte definieren Sie hier die Länge(n) sowie den anschließenden Winkel (falls vorhanden) der einzelnen Seiten des Produkts.
      </p>
      <p v-else>
        Bitte definieren Sie hier die Länge(n) der einzelnen Seiten des Produkts.
      </p>
      <v-container fluid>
        <DimensionsSide
          :side="side"
          :successor-side="index === sides.length - 1 ? null : sides[index+1]"
          :isLast="index === sides.length - 1"
          :index="index"
          v-for="(side, index) in sides"
          :key="side.id"
          :removable="isEditable"
          :is-angle-editable="isEditable"
          :sync-length-with="isRectangle ? sides.filter((s,i) => (index + 2) % 4 == i) : []"
        />
      </v-container>
      <v-row v-if="isEditable">
        <v-col cols="12" class="text-center">
          <v-btn @click="addSide('interrupt')">Unterbrechung hinzufügen</v-btn>
          <v-btn @click="addSide('side')">Seite hinzufügen</v-btn>
        </v-col>
      </v-row>
      <v-row v-if="product && product.key == 'product-palettenspeicher'">
        <p>Auch als T-Pfosten erhältich, bitte sprechen Sie uns an.</p>
      </v-row>
      <v-row :justify="'end'">
        <v-btn
          color="primary"
          elevation="2"
          @click="setGuideTab(1)"
        >Weiter</v-btn>
      </v-row>
      <v-row>
        <v-list>
          <v-list-item v-for="cuttedPart in cuttedParts" :key="cuttedPart.id">
            <v-list-item-content>
              1x Artikel {{ cuttedPart.article_number }} mit Originallänge {{ cuttedPart.original_length }}mm in folgende Teile einkürzen:<br/>
              <ul>
                <li>{{ cuttedPart.length }}mm</li>
                <li v-for="p in cuttedPart.reusing_parts" :key="p.id">{{ p.length }}mm</li>
              </ul>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-row>
    </v-expansion-panel-content>
  </div>
</template>

<script>
import DimensionsSide from './DimensionsSide.vue'
import Header from './parts/Header.vue'
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'Dimensions',
  components: {
    DimensionsSide,
    Header,
  },
  props: {
  },
  computed: {
    ...mapGetters({
      enabled: 'isProductConfigured',
      sides: 'computedSidesWithPartList',
      cuttedParts: 'cuttedParts',
      computedSidesWithPartList: 'computedSidesWithPartList'
    }),
    ...mapState({
      guideStep: state => state.guideStep,
      product: state => state.product,
      shaping: state => state.shaping,
    }),
    isEditable() {
      return this.shaping && this.shaping.key === 'shaping-custom';
    },
    isRectangle() {
      return this.shaping && this.shaping.key === 'shaping-o';
    }
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
    }
  },
  methods: {
    ...mapMutations([
      'setSides',
      'setGuideTab',
    ]),
    addSide(type) {
      let newSides = this.sides.concat([{
        'type': type,
        'length': this.product.defaultLength,
        'angle': type === 'interrupt' ? 0 : 90,
      }]);
      this.setSides(newSides);
    },
  },
}
</script>

<style scoped>
</style>
