<template>
  <g>
    <!--<circle cx="0" cy="0" r="20"/>-->
    <g v-if="angle2 === null" :transform="'rotate(' + (angle1) + ' 0 0) translate(-162 -162)'">
      <g
       id="g452"
       transform="matrix(0.73151994,0,0,-0.73151994,31.912557,31.912557)">
      <path
         d="M 0,40.5 C -22.36753,40.5 -40.5,22.36753 -40.5,0"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path454" />
    </g>
    <path
       d="M 32.278327,2.2859974 H 289.04182"
       style="fill:none;stroke:#ff0000;stroke-width:4.57199;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path456" />
    <g
       id="g458"
       transform="matrix(0.73151994,0,0,-0.73151994,288.67606,31.912557)">
      <path
         d="M 40.5,0 C 40.5,22.36753 22.36753,40.5 0,40.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path460" />
    </g>
    <path
       d="M 318.30262,32.278307 V 288.31027"
       style="fill:none;stroke:#ff0000;stroke-width:4.57199;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path462" />
    <g
       id="g464"
       transform="matrix(0.73151994,0,0,-0.73151994,288.67606,287.94462)">
      <path
         d="m 0,-40.5 c 22.36753,0 40.5,18.13247 40.5,40.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path466" />
    </g>
    <path
       d="M 289.04182,318.30265 H 32.278327"
       style="fill:none;stroke:#ff0000;stroke-width:4.57199;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path468" />
    <g
       id="g470"
       transform="matrix(0.73151994,0,0,-0.73151994,31.912557,287.94462)">
      <path
         d="m -40.5,0 c 0,-22.36753 18.13247,-40.5 40.5,-40.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path472" />
    </g>
    <path
       d="M 3.0175374,288.31027 V 32.278307"
       style="fill:none;stroke:#ff0000;stroke-width:4.57199;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path474" />
    <g
       id="g476"
       transform="matrix(0.73151994,0,0,-0.73151994,46.542967,273.31417)">
      <path
         d="M 16.5,0 C 16.5,9.1127 9.1127,16.5 0,16.5 -9.1127,16.5 -16.5,9.1127 -16.5,0 c 0,-9.1127 7.3873,-16.5 16.5,-16.5 9.1127,0 16.5,7.3873 16.5,16.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path478" />
    </g>
    <g
       id="g480"
       transform="matrix(0.73151994,0,0,-0.73151994,46.542967,46.542947)">
      <path
         d="M 16.5,0 C 16.5,9.1127 9.1127,16.5 0,16.5 -9.1127,16.5 -16.5,9.1127 -16.5,0 c 0,-9.1127 7.3873,-16.5 16.5,-16.5 9.1127,0 16.5,7.3873 16.5,16.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path482" />
    </g>
    <g
       id="g484"
       transform="matrix(0.73151994,0,0,-0.73151994,274.04565,273.31417)">
      <path
         d="M 16.5,0 C 16.5,9.1127 9.1127,16.5 0,16.5 -9.1127,16.5 -16.5,9.1127 -16.5,0 c 0,-9.1127 7.3873,-16.5 16.5,-16.5 9.1127,0 16.5,7.3873 16.5,16.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path486" />
    </g>
    <g
       id="g488"
       transform="matrix(0.73151994,0,0,-0.73151994,274.04565,46.542947)">
      <path
         d="M 16.5,0 C 16.5,9.1127 9.1127,16.5 0,16.5 -9.1127,16.5 -16.5,9.1127 -16.5,0 c 0,-9.1127 7.3873,-16.5 16.5,-16.5 9.1127,0 16.5,7.3873 16.5,16.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path490" />
    </g>
    <g
       id="g492"
       transform="matrix(0.73151994,0,0,-0.73151994,93.360242,93.360217)">
      <path
         d="M 0,10.5 C -5.79899,10.5 -10.5,5.79899 -10.5,0"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path494" />
    </g>
    <path
       d="M 93.726007,86.410789 H 227.59414"
       style="fill:none;stroke:#ff0000;stroke-width:4.57199;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path496" />
    <g
       id="g498"
       transform="matrix(0.73151994,0,0,-0.73151994,227.22838,93.360217)">
      <path
         d="M 10.5,0 C 10.5,5.79899 5.79899,10.5 0,10.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path500" />
    </g>
    <path
       d="M 234.90934,93.725983 V 226.86259"
       style="fill:none;stroke:#ff0000;stroke-width:4.57199;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path502" />
    <g
       id="g504"
       transform="matrix(0.73151994,0,0,-0.73151994,227.22838,226.49694)">
      <path
         d="m 0,-10.5 c 5.79899,0 10.5,4.70101 10.5,10.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path506" />
    </g>
    <path
       d="M 227.59414,234.1779 H 93.726007"
       style="fill:none;stroke:#ff0000;stroke-width:4.57199;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path508" />
    <g
       id="g510"
       transform="matrix(0.73151994,0,0,-0.73151994,93.360242,226.49694)">
      <path
         d="m -10.5,0 c 0,-5.79899 4.70101,-10.5 10.5,-10.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path512" />
    </g>
    <path
       d="M 86.410796,226.86259 V 93.725983"
       style="fill:none;stroke:#ff0000;stroke-width:4.57199;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path514" />
    <g
       id="g516"
       transform="matrix(0.73151994,0,0,-0.73151994,93.360242,93.360217)">
      <path
         d="M 0,3.5 C -1.933,3.5 -3.5,1.933 -3.5,0"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path518" />
    </g>
    <path
       d="M 93.726007,91.531423 H 227.59414"
       style="fill:none;stroke:#ff0000;stroke-width:4.57199;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path520" />
    <g
       id="g522"
       transform="matrix(0.73151994,0,0,-0.73151994,227.22838,93.360217)">
      <path
         d="M 3.5,0 C 3.5,1.933 1.933,3.5 0,3.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path524" />
    </g>
    <path
       d="M 229.7887,93.725983 V 226.86259"
       style="fill:none;stroke:#ff0000;stroke-width:4.57199;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path526" />
    <g
       id="g528"
       transform="matrix(0.73151994,0,0,-0.73151994,227.22838,226.49694)">
      <path
         d="m 0,-3.5 c 1.933,0 3.5,1.567 3.5,3.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path530" />
    </g>
    <path
       d="M 227.59414,229.05715 H 93.726007"
       style="fill:none;stroke:#ff0000;stroke-width:4.57199;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path532" />
    <g
       id="g534"
       transform="matrix(0.73151994,0,0,-0.73151994,93.360242,226.49694)">
      <path
         d="m -3.5,0 c 0,-1.933 1.567,-3.5 3.5,-3.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path536" />
    </g>
    <path
       d="M 91.531447,226.86259 V 93.725983"
       style="fill:none;stroke:#ff0000;stroke-width:4.57199;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path538" />
    <path
       d="m 234.90934,110.55095 h 98.02368 v -8.04673 h -98.02368 v 8.04673"
       style="fill:none;stroke:#ff0000;stroke-width:4.57199;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path540" />
    <path
       d="M 332.93302,102.50422 V 221.01054"
       style="fill:none;stroke:#ff0000;stroke-width:4.57199;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path542" />
    <path
       d="m 234.90934,212.96376 h 98.02368 v 8.04678 h -98.02368 v -8.04678"
       style="fill:none;stroke:#ff0000;stroke-width:4.57199;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path544" />
    </g>

    <g v-else-if="angle2 == 180" :transform="'rotate(' + (angle1 ) + ' 0 0) translate(-162 -162)'">
         <g
       id="g546"
       transform="matrix(0.73024994,0,0,-0.73024994,45.731899,31.857154)">
      <path
         d="M 0,40.5 C -22.36753,40.5 -40.5,22.36753 -40.5,0"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path548" />
    </g>
    <path
       d="M 46.097012,2.2820326 H 302.41475"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path550" />
    <g
       id="g552"
       transform="matrix(0.73024994,0,0,-0.73024994,302.04963,31.857154)">
      <path
         d="M 40.5,0 C 40.5,22.36753 22.36753,40.5 0,40.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path554" />
    </g>
    <path
       d="M 331.62474,32.222268 V 287.80975"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path556" />
    <g
       id="g558"
       transform="matrix(0.73024994,0,0,-0.73024994,302.04963,287.44462)">
      <path
         d="m 0,-40.5 c 22.36753,0 40.5,18.13247 40.5,40.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path560" />
    </g>
    <path
       d="M 302.41475,317.74999 H 46.097012"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path562" />
    <g
       id="g564"
       transform="matrix(0.73024994,0,0,-0.73024994,45.731899,287.44462)">
      <path
         d="m -40.5,0 c 0,-22.36753 18.13247,-40.5 40.5,-40.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path566" />
    </g>
    <path
       d="M 16.887018,287.80975 V 32.222268"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path568" />
    <g
       id="g570"
       transform="matrix(0.73024994,0,0,-0.73024994,61.067149,272.83962)">
      <path
         d="M 16.5,0 C 16.5,9.1127 9.1127,16.5 0,16.5 -9.1127,16.5 -16.5,9.1127 -16.5,0 c 0,-9.1127 7.3873,-16.5 16.5,-16.5 9.1127,0 16.5,7.3873 16.5,16.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path572" />
    </g>
    <g
       id="g574"
       transform="matrix(0.73024994,0,0,-0.73024994,61.067149,46.462143)">
      <path
         d="M 16.5,0 C 16.5,9.1127 9.1127,16.5 0,16.5 -9.1127,16.5 -16.5,9.1127 -16.5,0 c 0,-9.1127 7.3873,-16.5 16.5,-16.5 9.1127,0 16.5,7.3873 16.5,16.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path576" />
    </g>
    <g
       id="g578"
       transform="matrix(0.73024994,0,0,-0.73024994,287.44463,272.83962)">
      <path
         d="M 16.5,0 C 16.5,9.1127 9.1127,16.5 0,16.5 -9.1127,16.5 -16.5,9.1127 -16.5,0 c 0,-9.1127 7.3873,-16.5 16.5,-16.5 9.1127,0 16.5,7.3873 16.5,16.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path580" />
    </g>
    <g
       id="g582"
       transform="matrix(0.73024994,0,0,-0.73024994,287.44463,46.462143)">
      <path
         d="M 16.5,0 C 16.5,9.1127 9.1127,16.5 0,16.5 -9.1127,16.5 -16.5,9.1127 -16.5,0 c 0,-9.1127 7.3873,-16.5 16.5,-16.5 9.1127,0 16.5,7.3873 16.5,16.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path584" />
    </g>
    <g
       id="g586"
       transform="matrix(0.73024994,0,0,-0.73024994,107.80314,93.198137)">
      <path
         d="M 0,10.5 C -5.79899,10.5 -10.5,5.79899 -10.5,0"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path588" />
    </g>
    <path
       d="m 108.16826,86.260774 h 132.9055"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path590" />
    <g
       id="g592"
       transform="matrix(0.73024994,0,0,-0.73024994,240.70863,93.198137)">
      <path
         d="M 10.5,0 C 10.5,5.79899 5.79899,10.5 0,10.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path594" />
    </g>
    <path
       d="M 248.37626,93.563268 V 226.46875"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path596" />
    <g
       id="g598"
       transform="matrix(0.73024994,0,0,-0.73024994,240.70863,226.10362)">
      <path
         d="m 0,-10.5 c 5.79899,0 10.5,4.70101 10.5,10.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path600" />
    </g>
    <path
       d="M 241.07376,233.77125 H 108.16826"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path602" />
    <g
       id="g604"
       transform="matrix(0.73024994,0,0,-0.73024994,107.80314,226.10362)">
      <path
         d="m -10.5,0 c 0,-5.79899 4.70101,-10.5 10.5,-10.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path606" />
    </g>
    <path
       d="M 100.86576,226.46875 V 93.563268"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path608" />
    <g
       id="g610"
       transform="matrix(0.73024994,0,0,-0.73024994,107.80314,93.198137)">
      <path
         d="M 0,3.5 C -1.933,3.5 -3.5,1.933 -3.5,0"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path612" />
    </g>
    <path
       d="m 108.16826,91.372518 h 132.9055"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path614" />
    <g
       id="g616"
       transform="matrix(0.73024994,0,0,-0.73024994,240.70863,93.198137)">
      <path
         d="M 3.5,0 C 3.5,1.933 1.933,3.5 0,3.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path618" />
    </g>
    <path
       d="M 243.26451,93.563268 V 226.46875"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path620" />
    <g
       id="g622"
       transform="matrix(0.73024994,0,0,-0.73024994,240.70863,226.10362)">
      <path
         d="m 0,-3.5 c 1.933,0 3.5,1.567 3.5,3.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path624" />
    </g>
    <path
       d="M 241.07376,228.6595 H 108.16826"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path626" />
    <g
       id="g628"
       transform="matrix(0.73024994,0,0,-0.73024994,107.80314,226.10362)">
      <path
         d="m -3.5,0 c 0,-1.933 1.567,-3.5 3.5,-3.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path630" />
    </g>
    <path
       d="M 105.24726,226.46875 V 93.563268"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path632" />
    <path
       d="m 248.37626,110.35902 h 98.58373 v -8.03275 h -98.58373 v 8.03275"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path634" />
    <path
       d="M 346.95999,102.32627 V 220.62675"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path636" />
    <path
       d="m 248.37626,212.59401 h 98.58373 v 8.03274 h -98.58373 v -8.03274"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path638" />
    <path
       d="M 100.86576,110.35902 H 2.2820301 v -8.03275 H 100.86576 v 8.03275"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path640" />
    <path
       d="M 2.2820301,102.32627 V 220.62675"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path642" />
    <path
       d="M 100.86576,212.59401 H 2.2820301 v 8.03274 H 100.86576 v -8.03274"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path644" />
    </g>

   <g v-if="angle2 == 90 || angle2 == 270" :transform="'rotate(' + (angle1 + 180 + (angle2 == 270 ? 90 : 0)) + ' 0 0) ' + (angle2 == 270 ? 'translate(-175 -162)' : 'translate(-175 -162)')">
          <g
       id="g218"
       transform="matrix(0.73024994,0,0,-0.73024994,302.04907,31.857154)">
      <path
         d="M 40.5,0 C 40.5,22.36753 22.36753,40.5 0,40.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path220" />
    </g>
    <path
       d="M 332.35559,32.222164 V 287.80973"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path222" />
    <g
       id="g224"
       transform="matrix(0.73024994,0,0,-0.73024994,302.04907,287.44454)">
      <path
         d="m 0,-40.5 c 22.36753,0 40.5,18.13247 40.5,40.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path226" />
    </g>
    <path
       d="M 302.41477,317.01967 H 46.82843"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path228" />
    <g
       id="g230"
       transform="matrix(0.73024994,0,0,-0.73024994,46.46273,287.44454)">
      <path
         d="m -40.5,0 c 0,-22.36753 18.13247,-40.5 40.5,-40.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path232" />
    </g>
    <path
       d="M 16.8876,287.80973 V 32.222164"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path234" />
    <g
       id="g236"
       transform="matrix(0.73024994,0,0,-0.73024994,46.46273,31.857154)">
      <path
         d="M 0,40.5 C -22.36753,40.5 -40.5,22.36753 -40.5,0"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path238" />
    </g>
    <path
       d="M 46.82843,2.2820336 H 302.41477"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path240" />
    <g
       id="g242"
       transform="matrix(0.73024994,0,0,-0.73024994,61.068303,46.462044)">
      <path
         d="M 16.5,0 C 16.5,9.1127 9.1127,16.5 0,16.5 -9.1127,16.5 -16.5,9.1127 -16.5,0 c 0,-9.1127 7.3873,-16.5 16.5,-16.5 9.1127,0 16.5,7.3873 16.5,16.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path244" />
    </g>
    <g
       id="g246"
       transform="matrix(0.73024994,0,0,-0.73024994,287.44522,46.462044)">
      <path
         d="M 16.5,0 C 16.5,9.1127 9.1127,16.5 0,16.5 -9.1127,16.5 -16.5,9.1127 -16.5,0 c 0,-9.1127 7.3873,-16.5 16.5,-16.5 9.1127,0 16.5,7.3873 16.5,16.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path248" />
    </g>
    <g
       id="g250"
       transform="matrix(0.73024994,0,0,-0.73024994,61.068303,272.83949)">
      <path
         d="M 16.5,0 C 16.5,9.1127 9.1127,16.5 0,16.5 -9.1127,16.5 -16.5,9.1127 -16.5,0 c 0,-9.1127 7.3873,-16.5 16.5,-16.5 9.1127,0 16.5,7.3873 16.5,16.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path252" />
    </g>
    <g
       id="g254"
       transform="matrix(0.73024994,0,0,-0.73024994,287.44522,272.83949)">
      <path
         d="M 16.5,0 C 16.5,9.1127 9.1127,16.5 0,16.5 -9.1127,16.5 -16.5,9.1127 -16.5,0 c 0,-9.1127 7.3873,-16.5 16.5,-16.5 9.1127,0 16.5,7.3873 16.5,16.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path256" />
    </g>
    <g
       id="g258"
       transform="matrix(0.73024994,0,0,-0.73024994,240.70807,93.197982)">
      <path
         d="M 10.5,0 C 10.5,5.79899 5.79899,10.5 0,10.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path260" />
    </g>
    <path
       d="M 248.37569,93.563164 V 226.46873"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path262" />
    <g
       id="g264"
       transform="matrix(0.73024994,0,0,-0.73024994,240.70807,226.10355)">
      <path
         d="m 0,-10.5 c 5.79899,0 10.5,4.70101 10.5,10.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path266" />
    </g>
    <path
       d="M 241.07377,233.77117 H 108.16943"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path268" />
    <g
       id="g270"
       transform="matrix(0.73024994,0,0,-0.73024994,107.80373,226.10355)">
      <path
         d="m -10.5,0 c 0,-5.79899 4.70101,-10.5 10.5,-10.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path272" />
    </g>
    <path
       d="M 100.86578,226.46873 V 93.563164"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path274" />
    <g
       id="g276"
       transform="matrix(0.73024994,0,0,-0.73024994,107.80373,93.197982)">
      <path
         d="M 0,10.5 C -5.79899,10.5 -10.5,5.79899 -10.5,0"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path278" />
    </g>
    <path
       d="M 108.16943,86.260722 H 241.07377"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path280" />
    <g
       id="g282"
       transform="matrix(0.73024994,0,0,-0.73024994,240.70807,93.197982)">
      <path
         d="M 3.5,0 C 3.5,1.933 1.933,3.5 0,3.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path284" />
    </g>
    <path
       d="M 243.26452,93.563164 V 226.46873"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path286" />
    <g
       id="g288"
       transform="matrix(0.73024994,0,0,-0.73024994,240.70807,226.10355)">
      <path
         d="m 0,-3.5 c 1.933,0 3.5,1.567 3.5,3.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path290" />
    </g>
    <path
       d="M 241.07377,228.65948 H 108.16943"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path292" />
    <g
       id="g294"
       transform="matrix(0.73024994,0,0,-0.73024994,107.80373,226.10355)">
      <path
         d="m -3.5,0 c 0,-1.933 1.567,-3.5 3.5,-3.5"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path296" />
    </g>
    <path
       d="M 105.97868,226.46873 V 93.563164"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path298" />
    <g
       id="g300"
       transform="matrix(0.73024994,0,0,-0.73024994,107.80373,93.197982)">
      <path
         d="M 0,3.5 C -1.933,3.5 -3.5,1.933 -3.5,0"
         style="fill:none;stroke:#ff0000;stroke-width:6.25;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
         id="path302" />
    </g>
    <path
       d="M 108.16943,90.642222 H 241.07377"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path304" />
    <path
       d="m 224.27744,233.77117 v 98.58375 h 8.03333 v -98.58375 h -8.03333"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path306" />
    <path
       d="M 232.31077,332.35492 H 114.01028"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path308" />
    <path
       d="m 122.0436,233.77117 v 98.58375 h -8.03332 v -98.58375 h 8.03332"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path310" />
    <path
       d="M 100.86578,110.35897 H 2.2820301 v -8.03281 H 100.86578 v 8.03281"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path312" />
    <path
       d="M 2.2820301,102.32616 V 220.62667"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path314" />
    <path
       d="M 100.86578,212.59386 H 2.2820301 v 8.03281 H 100.86578 v -8.03281"
       style="fill:none;stroke:#ff0000;stroke-width:4.56406;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
       id="path316" />
    </g>
    <LabelAngle :angle1="angle1" :angle2="angle2" />
  </g>
</template>

<script>
import { mapState } from 'vuex'

import LabelAngle from './LabelAngle.vue'

export default {
  name: 'PNode',
  props: {
    angle1: {
      type: Number,
      default: 0,
    },
    angle2: {
      type: Number,
      default: null
    }
  },
  components: {
    LabelAngle,
  },
  computed: {
    ...mapState({
      sides: state => state.sides,
    }),
  }

}
</script>

<style scoped>
</style>
