<template>
<div>
    <Header
      :title="'Produktgruppe'"
      :subtitle="(selectedProduct) ? selectedProduct.name : ''"
      :type="(selectedProduct) ? 'success' : undefined"
    />
    <v-expansion-panel-content>
      <p>Bitte wählen Sie die gewünschte Produktgruppe:</p>
      <v-row>
        <v-col
          v-for="product in products"
          :key="product.key"
          sm="6"
          lg="4"
          xl="3"
        >
          <Card
            :image="publicPath + product.image"
            :title="product.name"
            :selected="selectedProduct && selectedProduct.key === product.key"
            @click="setProduct(product)"
          />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
    </div>
</template>

<script>
import Card from './parts/Card.vue'
import Header from './parts/Header.vue'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'ProductGroup',
  components: {
    Card,
    Header,
  },
  props: {
  },
  computed: {
    //selectedProduct () { this.products.find(p => this.) },
    ...mapState({
      selectedProduct: state => state.product
    })
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      products: [
        {
          defaultLength: 1500,
          key: 'product-fussgaengerschutz',
          name: 'Fußgängerschutz',
          image: 'images/products/fussgaengerschutz.jpg',
        },
        {
          defaultLength: 2900,
          key: 'product-wandschutz',
          name: 'Wandschutz',
          image: 'images/products/wandschutz.jpg',
        },
        {
          defaultLength: 2900,
          key: 'product-guardrail',
          name: 'Palettenspeicher 900',
          image: 'images/products/guardrail.jpg',
        },
        {
          defaultLength: 2900,
          key: 'product-palettenspeicher',
          name: 'Palettenspeicher 2200',
          image: 'images/products/palettenspeicher.jpg',
        },
      ]
    }
  },
  methods: {
    ...mapMutations([
      'setProduct',
    ]),
  }
}
</script>

<style scoped>
</style>
