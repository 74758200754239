<template>
  <g>
    <!--<circle cx="0" cy="0" r="2"/>-->
    <path style="fill:none;stroke-miterlimit:1.6;stroke:#f07e1a;" :d="
      'M0,0' +
      'L' + width1 + ',0' +
      'L' + width1 + ',' + height1 +
      'L0,' + height1 + 
      'L0,0'"
      :transform="'translate(' + offset1 + ' -' + height1/2 + ')'" />
    <path
      v-if="mitStaplerbarriere"
      style="fill:none;stroke-miterlimit:0.5;stroke:#e52521;"
      :d="
      'M0,0' +
      'L' + width2 + ',0' +
      'L' + width2 + ',' + height2 +
      'L0,' + height2 + 
      'L0,0'"
      :transform="'translate(' + offset2 + ' -' + height2/2 + ')'" />
    <Label
      :transform="'translate(' + (offset1 + (isFirstSideElement ? 0 : -10)) + ' 0)'"
      :length="width1 + (isFirstSideElement ? 0 : 10)"
      :lengthMm="lengthMm"
      :originalLengthMm="originalLengthMm"
      :type="'edge'"
      :isFirstSideElement="isFirstSideElement"
      :isLastSideElement="isLastSideElement"
      :hasSideLabel="hasSideLabel"
    />
  </g>
</template>

<script>
import { mapState } from 'vuex'

import Label from './Label.vue'

export const LENGTH_MM = 1500;
export const LENGTH    = 1500;

export default {
  name: 'FBarriere',
  props: {
    lengthMm: { // the length to display
      type: Number,
      default: LENGTH_MM
    },
    length: {
      type: Number,
      default: LENGTH,
    },
    mitStaplerbarriere: {
      type: Boolean,
      default: false,
    },
    isFirstSideElement: {
      type: Boolean,
      default: false,
    },
    isLastSideElement: {
      type: Boolean,
      default: false,
    },
    hasSideLabel: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Label,
  },
  computed: {
    ...mapState({
      sides: state => state.sides,
      productDetails: state => state.productDetails,
    }),
    height1() {
      return 35;
    },
    width1() {
      return this.lengthMm;// 1500;
    },
    offset1() {
      return 12;
    },
    height2() {
      return 105;
    },
    width2() {
      return 1348 - (LENGTH_MM - this.lengthMm);
    },
    offset2() {
      return 76 + this.offset1;
    },
    originalLengthMm() {
      return LENGTH_MM;
    },
  }

}
</script>

<style scoped>
</style>
