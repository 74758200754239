<template>
  <v-card flat>
    <v-card-subtitle class="text-center" v-if="!imageOnly && guideTab == 0">
      <div v-if="!product">
        Herzlich Willkommen. Bitte wählen Sie rechts ein Produkt, um mit der Konfiguration zu starten.
      </div>
      <div v-else-if="!anchoring">
        Bitte wählen Sie eine Verankerungsart.
      </div>
      <div v-else-if="!shaping">
        Bitte wählen Sie eine Form.
      </div>
      <div v-else>
        Sind Sie fertig? Laden Sie sich Ihre Konfiguration inkl. Stückliste herunter.
      </div>
    </v-card-subtitle>
    <v-card-subtitle class="text-center" v-if="guideTab == 1 && currentAdditionalProduct && currentAdditionalProduct.text && currentAdditionalProduct.image">
      {{currentAdditionalProduct.text}}
    </v-card-subtitle>
    <v-card-subtitle class="text-center" v-if="guideTab == 1 && (!currentAdditionalProduct || !currentAdditionalProduct.text || !currentAdditionalProduct.image)">
      Ergänzen Sie bei Bedarf weitere Produkte aus unserem Portfolio zu Ihrer Anfrage.
      Wählen Sie dazu auf der rechten Seiten ein Produkt.
    </v-card-subtitle>
    <center>
      <v-img v-if="imgSrc" :src="imgSrc" :width="guideTab == 0 ? '100%' : '30%'" />
    </center>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'ProductInfos',
  components: {
  },
  props: {
    msg: String,
    imageOnly: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
    }
  },
  computed: {
    ...mapGetters({
      isProductConfigured: 'isProductConfigured'
    }),
    ...mapState({
      product: state => state.product,
      productDetails: state => state.productDetails,
      sides: state => state.sides,
      shaping: state => state.shaping,
      anchoring: state => state.anchoring,
      currentAdditionalProduct: state => state.currentAdditionalProduct,
      guideTab: state => state.guideTab,
    }),
    imgSrc() {
      if(this.guideTab === 1) {
        return this.currentAdditionalProduct && this.currentAdditionalProduct.image ? this.publicPath + this.currentAdditionalProduct.image : null;
      }
      if(!this.product) return null;
      let folder   = 'products';
      let filename = this.product.key.replace('product-', ''); // fussgaengerschutz,wandschutz,guardrail
      if(this.isProductConfigured) {
        if(['product-fussgaengerschutz', 'product-wandschutz'].includes(this.product.key) && this.productDetails) {
          filename += '-' + this.productDetails.key;
        }

        if(this.shaping) {
          folder = 'shapings';
          filename += '-' + this.shaping.key.replace('shaping-', '');
        }
      }
      return this.publicPath + 'images/' + folder + '/' + filename + '.jpg';
    },
  },
}
</script>

<style scoped>
</style>
