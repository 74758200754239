import Vue from 'vue'
import Vuex from 'vuex'
import { v4 as uuidv4 } from 'uuid';

import {getSideOffsetX, getSideOffsetY} from './helpers/side-offset-calculator'
import {default as getPartListForSide} from './helpers/partlist-generator/index';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    additionalArticleList: [], // contains addition article numbers, where an article_number can be repeated multiple times
    additionalArticleAmountList: [], // contains addition article products
    additionalArticleCount: 0, // number of additional articles where count can differ additionalArticleList.length due to custom-configuration-articles
    anchoring: null, // the selected anchoring from Anchoring.vue
    drawingSvg: "", // the svg-code.
    product: null, // the selected product from ProductGroup.vue
    productDetails: null,
    shaping: null, // the selected shaping from Shaping.vue
    sides: null, //
    guideStep: 0,
    guideTab: 0,
    presentationTab: 0,
    currentAdditionalProduct: null,
  },
  mutations: {
    setAdditionalArticleList(state, list) {
      state.additionalArticleList = list;
    },
    setAdditionalArticleAmountList(state, list) {
      state.additionalArticleAmountList = list;
    },
    setAdditionalArticleCount(state, count) {
      state.additionalArticleCount = count;
    },
    setAnchoring (state, anchoring) {
      state.anchoring = anchoring
    },
    setDrawingSvg (state, svg) { // svg= {svg, width, height}
      state.drawingSvg = svg;
    },
    setProduct (state, product) {
      let isProductChanged = state.product != product;
      state.product = product
      if(isProductChanged) {
        state.anchoring = null;
        state.productDetails = null;
        state.sides = null;
        state.shaping = null;
      }
    },
    setProductDetails (state, productDetails) {
      state.productDetails = productDetails
    },
    setShaping (state, shaping) {
      state.shaping = shaping
    },
    setSides (state, sides) {
      state.sides = sides;
    },
    setGuideStep (state, guideStep) {
      state.guideStep = guideStep;
    },
    setGuideTab (state, guideTab) {
      state.guideTab = guideTab;
    },
    setPresentationTab (state, presentationTab) {
      state.presentationTab = presentationTab;
    },
    removeSide (state, index) {
      state.sides.splice(index, 1);
    },
    addSide(state, {index, side}) {
      if(!index && index !== 0) {
        index = state.sides.length-1;
      }
      state.sides.splice(index, 0, side)
    },
    setCurrentAdditionalProduct(state, additionalProduct) {
      state.currentAdditionalProduct = additionalProduct;
    },
    resetConfiguration(state) {
      state.product = null;
      state.productDetails = null,
      state.shaping = null
      state.sides = null;
      state.guideStep = 0;
    }
  },
  actions: {
  },
  getters: {
    isProductConfigured: state => {
      return !!state.product
        &&
          (!['product-fussgaengerschutz', 'product-wandschutz'].includes(state.product.key)
          || (state.product.key === 'product-fussgaengerschutz' && state.productDetails && ['mit-staplerbarriere', 'ohne-staplerbarriere'].includes(state.productDetails.key))
          || (state.product.key === 'product-wandschutz' && state.productDetails && ['flach', 'aufgedoppelt'].includes(state.productDetails.key))
          || state.product.key === 'product-guardrail'
        );
    },
    computedSides: state => {
      if(!state.sides) {
        return [];
      }

      let lengthFactor = 1;
      let sideCount    = 0;

      return state.sides.reduce((arr, curr, currIndex) => {
        let prev = (arr.length > 0) ? arr[arr.length -1 ] : null;
        let next = (currIndex+1 < state.sides.length) ? state.sides[currIndex+1] : null;
        let transform = "";
        if(prev) {
          transform = prev.transform + 
            " translate(" + 
              (prev.length / lengthFactor + getSideOffsetX(state.product, curr, prev)) + " " + 
              getSideOffsetY(state.product, curr, prev) +
            ") rotate(" + prev.angle + ")";
        }
        arr.push({
          id:           curr.id,
          type:         curr.type,
          name:         curr.type === 'interrupt' ? 'Unterbrechung' : 'Seite ' + ++sideCount,
          length:       curr.length,
          angle:        curr.angle,
          custom_angle: !!curr.custom_angle,
          set:          (key, value) => Vue.set(curr, key, value),
          transform:    transform,
          is_first:     prev === null || prev.type === 'interrupt' || prev.custom_angle === true,
          is_last:      next === null || next.type === 'interrupt' || curr.custom_angle === true,
        });
        return arr;
      }, [])
    },
    computedSidesWithPartList: (state, getters) => {
      let partStore = [];
      return getters.computedSides.map((side, currIndex, arr) => {
        let prev = (arr.length > 0) ? arr[arr.length - 1] : null;
        let next = (currIndex+1 < arr.length) ? arr[currIndex + 1] : null;
        let parts = getPartListForSide(state.product, state.anchoring, state.productDetails, state.shaping, side, prev, next);

        let cuttedParts = parts.filter(p => typeof p === 'object');
        cuttedParts.forEach(p => {
          p.id = uuidv4();
          p.reused_part = null
          p.reused = false;

          let availablePartFromPartStore = partStore.filter(ps => ps.article_number == p.article_number && ps.length >= p.length).shift();
          if(availablePartFromPartStore) {
            p.reused_part = availablePartFromPartStore.id;
            availablePartFromPartStore.length = availablePartFromPartStore.length - p.length;
            availablePartFromPartStore.reused = true;
          } else {
            partStore.push({
              id: p.id,
              article_number: p.article_number,
              length: p.original_length - p.length,
            })
          }
        });

        let s = Object.assign(
          {},
          side,
          {parts: parts}
        );
        return s;
      })
    },

    /**
     * list of article numbers (only not-reused parts are returned)
     */
    partListForSides: (state, getters) => {
      return getters.computedSidesWithPartList.flatMap(side => side.parts.filter(part => Number.isInteger(part) || part.reused_part == null).map(part => Number.isInteger(part) ? part : part.article_number));
    },
    cuttedParts: (state, getters) => {
      // get all parts which are cutted but not reuses another part
      let reusedOriginalParts = getters.computedSidesWithPartList.flatMap(side => side.parts.filter(part => typeof part == 'object' && part.reused_part == null));
      return reusedOriginalParts.map(p => {
        // add parts which reuses this part
        p.reusing_parts = getters.computedSidesWithPartList.flatMap(side => side.parts.filter(part => part.reused_part == p.id));
        return p;
      })
    }
  },
  modules: {
  }
})
