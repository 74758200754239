<template>
  <g>
    <PNode :transform="'translate(0 0)'" v-if="isFirst" />
    <g v-for="i in (numberOfParts - 1)" :key="i">
      <PEdge :transform="'translate(' + ((i-1) * (sideLength + middleConsoleOffsetRight) + leftOffset) + ' 0)'" :is-vertex="false" :isFirstSideElement="i==1" :hasSideLabel="numberOfParts > 1"/>
      <PNode :transform="'translate(' + ((i) * (sideLength + middleConsoleOffsetLeft) + (i-1)* (middleConsoleOffsetRight-middleConsoleOffsetLeft) + leftOffset) + ' 0)'" :angle1="0" :angle2="180"/>
    </g>
    <PEdge
      :transform="'translate(' + ((numberOfParts - 1) * (sideLength + middleConsoleOffsetRight) + leftOffset) + ' 0)'"
      :lengthMm="lastPartLengthMm"
      :length="lastPartLength"
      :is-vertex="angle !== null"
      :isFirstSideElement="numberOfParts == 1"
      :isLastSideElement="true"
      :hasSideLabel="numberOfParts > 1"
    />
    <PNode v-if="!side.custom_angle || angle === null" :transform="'translate(' + ((numberOfParts - 1) * (sideLength + middleConsoleOffsetLeft)  + leftOffset*2 + (numberOfParts-1)* (middleConsoleOffsetRight-middleConsoleOffsetLeft) + lastPartLength) + ' 0)'" :angle1="180" :angle2="angle"/>
    <template v-else>
      <PNode :transform="'translate(' + ((numberOfParts - 1) * (sideLength + middleConsoleOffsetLeft)  + leftOffset*2 + (numberOfParts-1)* (middleConsoleOffsetRight-middleConsoleOffsetLeft) + lastPartLength) + ' 0)'" :angle1="180"/>
      <PNode :transform="'translate(' + ((numberOfParts - 1) * (sideLength + middleConsoleOffsetLeft)  + leftOffset*2 + (numberOfParts-1)* (middleConsoleOffsetRight-middleConsoleOffsetLeft) + lastPartLength) + ' 0) translate(360 0)'" :angle1="angle"/>
    </template>
    <Label
      transform="translate(74 0)"
      :length="((numberOfParts-1)*partLength+lastPartLength) + numberOfParts*65"
      :lengthMm="length"
      :type="'side'"
      v-if="numberOfParts > 1"
    />
  </g>
</template>

<script>
import { mapState } from 'vuex'
import PEdge from './PEdge.vue'
import {LENGTH_MM, LENGTH} from './PEdge.vue'

import PNode from './PNode.vue'
export const LENGTH_FACTOR = LENGTH_MM/LENGTH;

import Label from './Label.vue'

export default {
  name: 'PSide',
  props: {
    side: Object,
    length: {
      type: Number,
      default: 2900,
    },
    angle: {
      type: Number,
      default: null,
    },
    isFirst: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    PEdge,
    PNode,
    Label,
  },
  data () {
    return {
      sideLength: 2900,
      leftOffset: 50,
      middleConsoleOffsetLeft: 84,
      middleConsoleOffsetRight:145,
    }
  },
  computed: {
    ...mapState({
      sides: state => state.sides,
    }),
    numberOfParts() {
      return Math.ceil(this.length / LENGTH_MM);
    },
    partLength() {
      return 2948;
    },
    lastPartLengthMm() {
      let mod = this.length % LENGTH_MM;
      if(mod === 0) {
        return LENGTH_MM;
      }
      return mod;
    },
    lastPartLength() {
      return this.lastPartLengthMm + 48
    }
  }

}
</script>

<style scoped>
</style>
