import { render, staticRenderFns } from "./WNode.vue?vue&type=template&id=5d52908f&scoped=true"
import script from "./WNode.vue?vue&type=script&lang=js"
export * from "./WNode.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d52908f",
  null
  
)

export default component.exports