<template>
  <g>
    <g :transform="'translate(24 ' + (-104/2) + ')'">
      <path id="path698" style="fill:none;stroke:#f07e1a;stroke-miterlimit:1.6;" :d="'M0,0H'+lengthMm" />
      <path id="path700" style="fill:none;stroke:#f07e1a;stroke-miterlimit:1.6;" :d="'M0,104H'+lengthMm" />
    </g>
    <Label
      :transform="'translate(-50 0)'"
      :length="length+110"
      :lengthMm="lengthMm"
      :originalLengthMm="originalLengthMm"
      :type="'edge'"
      :isFirstSideElement="isFirstSideElement"
      :isLastSideElement="isLastSideElement"
      :hasSideLabel="hasSideLabel"
    />
  </g>
</template>

<script>
import { mapState } from 'vuex'

import Label from './Label.vue'

export const LENGTH_MM = 2900;
export const LENGTH    = 2900;

export default {
  name: 'PEdge',
  props: {
    lengthMm: { // the length to display
      type: Number,
      default: LENGTH_MM,
    },
    length: {
      type: Number,
      default: LENGTH,
    },
    isVertex: {
      type: Boolean,
      default: false,
    },
    isFirstSideElement: {
      type: Boolean,
      default: false,
    },
    isLastSideElement: {
      type: Boolean,
      default: false,
    },
    hasSideLabel: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Label,
  },
  computed: {
    ...mapState({
      sides: state => state.sides,
    }),
    height1() {
      return 42.22;
    },
    width1() {
      return this.length - (this.offset1 + this.offset2);
    },
    offset1() {
      return 24;
    },
    offset2() {
      return (this.isVertex) ? 85 : this.offset1;
    },
    originalWidth() {
      return LENGTH - (this.offset1 + this.offset2);
    },
    originalLength() {
      return LENGTH;
    },
    originalLengthMm() {
      return LENGTH_MM;
    },
  }

}
</script>

<style scoped>
</style>
