<template>
  <v-card>
    <v-tabs
      v-model="tab"
      grow
    >
      <v-tab :key="0" >
        <template v-if="!partList || partList.length == 0">
          Konfigurator
        </template>
        <v-badge
          v-else
          color="primary"
          content="✓"
        >
          Konfigurator
        </v-badge>
      </v-tab>
      <v-tab :key="1">
        <template v-if="!additionalProducts || additionalProducts.length == 0">
          Weitere Produkte
        </template>
        <v-badge
          v-else
          color="primary"
          :content="additionalProductCount"
        >
          Weitere Produkte
        </v-badge>
      </v-tab>
      <v-tab
        :key="2"
        :disabled="(!isProductConfigured || !computedSides || computedSides.length === 0 || (!anchoring && (!product || product.key != 'product-palettenspeicher' && product.key != 'product-guardrail'))) && (!additionalProducts || additionalProducts.length === 0)"
      >
        Drucken/Senden
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item :key="0">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel :key="0"><ProductGroup  :key="0"/></v-expansion-panel>
          <v-expansion-panel :key="1" :disabled="!product" v-show="hasProductDetails" ><ProductDetails :key="1"/></v-expansion-panel>
          <v-expansion-panel :key="2" :disabled="!isProductConfigured" v-show="hasDifferentAnchorings" ><Anchoring :key="2"/></v-expansion-panel>
          <v-expansion-panel :key="3" :disabled="!product || ((!anchoring && product.key != 'product-guardrail') && (!product || product.key != 'product-palettenspeicher')) || !isProductConfigured"><Shaping :key="3"/></v-expansion-panel>
          <v-expansion-panel :key="4" :disabled="!shaping"><Dimensions :key="4"/></v-expansion-panel>
        </v-expansion-panels>
      </v-tab-item>
      <v-tab-item :key="1">
        <v-card-text>
          <AdditionalProducts />
        </v-card-text>
      </v-tab-item>
      <v-tab-item :key="2">
        <v-card-text>
          <Checkout />
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>

import { mapGetters, mapState } from 'vuex'

import Anchoring from './Anchoring.vue'
import Checkout from './Checkout.vue'
import Dimensions from './Dimensions.vue'
import ProductDetails from './ProductDetails.vue'
import ProductGroup from './ProductGroup.vue'
import AdditionalProducts from './AdditionalProducts.vue'
import Shaping from './Shaping.vue'


export default {
  name: 'Guide',
  components: {
    AdditionalProducts,
    Anchoring,
    Checkout,
    Dimensions,
    ProductDetails,
    ProductGroup,
    Shaping,
  },
  props: {
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      isProductConfigured: 'isProductConfigured',
      computedSides: 'computedSides',
      partList: 'partListForSides',
    }),
    ...mapState({
      product: state => state.product,
      productDetails: state => state.productDetails,
      sides: state => state.sides,
      shaping: state => state.shaping,
      anchoring: state => state.anchoring,
      guideStep: state => state.guideStep,
      guideTab: state => state.guideTab,
      additionalProducts: state => state.additionalArticleList,
      additionalProductCount: state => state.additionalArticleCount,
    }),
    hasDifferentAnchorings() {
      return this.product && ['product-fussgaengerschutz', 'product-wandschutz'].includes(this.product.key)
    },
    hasProductDetails() {
      return this.hasProductProductDetails(this.product);
    },
    panel: {
      get () {
        return this.guideStep;
      },
      set (value) {
        this.$store.commit('setGuideStep', value)
      }
    },
    tab: {
      get () {
        return this.guideTab;
      },
      set (value) {
        this.$store.commit('setGuideTab', value)
      }
    },
  },
  watch: {
    product () {
      if(!this.product) {
        return;
      }
      if(this.product.key == "product-guardrail") {
        this.panel+=3;
      }else if(this.hasProductProductDetails(this.product)){
        this.panel++;
      }else{
        this.panel+=2;
      }
    },
    productDetails() {
      if(this.product && this.product.key !== 'product-palettenspeicher' && this.productDetails != null) {
        this.panel++;
      }
    },
    anchoring() {
      if(this.anchoring) {
        this.panel ++;
      }
    },
    shaping() {
      if(this.shaping) this.panel ++;
    }
  },
  methods: {
    hasProductProductDetails(product) {
      return product && ['product-fussgaengerschutz',  'product-palettenspeicher', 'product-wandschutz'].includes(product.key)
    }
  }
}
</script>

<style scoped>
</style>
