<template>
  <v-card
    style="height: 100%"
  >
    <v-card-title>
      Leitplanken konfigurieren & hinzufügen
    </v-card-title>
    <v-container fluid>
      <v-row>
        <v-col
          class="d-flex"
          cols="12"
          md="6"
        >
          <v-select
            :items="['Normal', 'Fußbodenheizung']"
            label="Verankerungsart"
            v-model="anchoring"
          ></v-select>
        </v-col>
        <v-col
          class="d-flex"
          cols="12"
          md="6"
        >
          <v-select
            :items="['Gelb', 'Gelb/Schwarz']"
            label="Farbe"
            v-model="color"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="d-flex"
          cols="12"
          md="6"
        >
          <v-select
            :items="['Höhe 265', 'Höhe 495']"
            label="Variante"
            v-model="variant"
          ></v-select>
        </v-col>
        <v-col
          class="d-flex"
          cols="12"
          md="6"
        >
          <v-select
            :items="[{value: 1450, text: '145cm'}, {value: 2900, text: '290cm'}]"
            label="Länge"
            v-model="length"
            suffic="mm"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col></v-col>
        <v-col>
          <v-btn
            color="primary"
            :disabled="!variant || !anchoring || !color || !length"
            @click="addConfiguration"
          >
            Hinzufügen
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="success"
      color="green"
      rounded="pill"
      centered
      timeout="5000"
    >
      Ihre Konfiguration wurde hinzugefügt.

      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          text
          v-bind="attrs"
          @click="success = false"
        >
          Ok.
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import {default as ARTICLES} from '../../../store/helpers/partlist-generator/wandschutz.articles';

export default {
  name: 'AdditionalProductsLeitplanke',
  components: {
  },
  props: {
    element: Object,
  },
  computed: {
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      variant: null,
      length: 2900,
      color: null,
      anchoring: null, 
      success: false,
    }
  },
  methods: {
    addConfiguration() {
      if(this.length === 0) {
        return;
      }
      let lastPartLength2900mm = this.length % 2900;
      let numberOfParts2900mm  = (this.length - lastPartLength2900mm) / 2900;
      let numberOfConnectors = numberOfParts2900mm - 1 + (lastPartLength2900mm > 0 ? 1 : 0)
      let parts = [];
      let mittelkonsolen = [
        {anchoring: 'Normal', variant: 'Höhe 265', color: 'Gelb', article_number: 0},
        {anchoring: 'Normal', variant: 'Höhe 495', color: 'Gelb', article_number: 0},
        {anchoring: 'Normal', variant: 'Höhe 265', color: 'Gelb/Schwarz', article_number: 0},
        {anchoring: 'Normal', variant: 'Höhe 495', color: 'Gelb/Schwarz', article_number: 0},
        {anchoring: 'Fußbodenheizung', variant: 'Höhe 265', color: 'Gelb', article_number: 0},
        {anchoring: 'Fußbodenheizung', variant: 'Höhe 495', color: 'Gelb', article_number: 0},
        {anchoring: 'Fußbodenheizung', variant: 'Höhe 265', color: 'Gelb/Schwarz', article_number: 0},
        {anchoring: 'Fußbodenheizung', variant: 'Höhe 495', color: 'Gelb/Schwarz', article_number: 0},
      ];
      let endkonsolen = [
        {anchoring: 'Normal', variant: 'Höhe 265', color: 'Gelb', article_number: 0},
        {anchoring: 'Normal', variant: 'Höhe 495', color: 'Gelb', article_number: 0},
        {anchoring: 'Normal', variant: 'Höhe 265', color: 'Gelb/Schwarz', article_number: 0},
        {anchoring: 'Normal', variant: 'Höhe 495', color: 'Gelb/Schwarz', article_number: 0},
        {anchoring: 'Fußbodenheizung', variant: 'Höhe 265', color: 'Gelb', article_number: 0},
        {anchoring: 'Fußbodenheizung', variant: 'Höhe 495', color: 'Gelb', article_number: 0},
        {anchoring: 'Fußbodenheizung', variant: 'Höhe 265', color: 'Gelb/Schwarz', article_number: 0},
        {anchoring: 'Fußbodenheizung', variant: 'Höhe 495', color: 'Gelb/Schwarz', article_number: 0},
      ];

      parts = parts.concat(Array(numberOfParts2900mm * (this.variant == 'Höhe 265' ? 1 : 2)).fill({
        'article_number': ARTICLES.BARRIERE_3000,
        'original_length': 2900,
        'length': 2900,
      }));
      parts = parts.concat(Array(numberOfConnectors).fill({
        'article_number': mittelkonsolen.find(m => m.anchoring == this.anchoring && m.variant == this.variant && m.color == this.color)?.article_number
      }));
      // endkonsole
      parts = parts.concat(Array(2).fill({
        'article_number': endkonsolen.find(m => m.anchoring == this.anchoring && m.variant == this.variant && m.color == this.color)?.article_number
      }));

     if(lastPartLength2900mm <= 1450 && lastPartLength2900mm > 0) {
        parts.concat(Array((this.variant == 'Höhe 265' ? 1 : 2)).fill({
          'article_number': ARTICLES.BARRIERE_1450,
          'original_length': 1450,
          'length': lastPartLength2900mm,
        }))
      }else if(lastPartLength2900mm > 0) {
        parts.concat(Array((this.variant == 'Höhe 265' ? 1 : 2)).fill({
          'article_number': ARTICLES.BARRIERE_2900,
          'original_length': 2900,
          'length': lastPartLength2900mm,
        }))
      }

      this.element.configurations.push({
        name: 'Leitplanke ' + this.variant + ' ' + this.color + ' ' + this.anchoring + ' ' + this.length + 'mm',
        parts: parts,
      });
      this.success = true;
    },
  },
  watch: {
  }
}
</script>

<style scoped>
  .amount{
    width: 120px;
  }
  .v-card__text, .v-card__title {
    word-break: normal !important;
  }
</style>
