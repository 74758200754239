<template>
  <g>
    <path style="fill:none;stroke-miterlimit:1.6;stroke:#f07e1a;" :d="
      'M0,0' +
      'L' + width1 + ',0' +
      'L' + width1 + ',' + height1 +
      'L0,' + height1 + 
      'L0,0'"
      :transform="'translate(' + offset1 + ' -' + height1/2 + ')'" />
    <Label
      :transform="'translate(' + (offset1 + (isFirstSideElement ? 0 : -10)) + ' 0)'"
      :length="width1 + (isFirstSideElement ? 0 : 10)"
      :lengthMm="lengthMm"
      :originalLengthMm="originalLengthMm"
      :type="'edge'"
      :isFirstSideElement="isFirstSideElement"
      :isLastSideElement="isLastSideElement"
      :hasSideLabel="hasSideLabel"
    />
  </g>
</template>

<script>
import { mapState } from 'vuex'

import Label from './Label.vue'

export const LENGTH_MM = 2900;
export const LENGTH    = 2900;

export default {
  name: 'WEdge',
  props: {
    originalLengthMm: { // the length to display
      type: Number,
      default: LENGTH_MM,
    },
    lengthMm: { // the length to display
      type: Number,
      default: LENGTH_MM,
    },
    length: {
      type: Number,
      default: LENGTH,
    },
    isFirstSideElement: {
      type: Boolean,
      default: false,
    },
    isLastSideElement: {
      type: Boolean,
      default: false,
    },
    hasSideLabel: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Label,
  },
  computed: {
    ...mapState({
      sides: state => state.sides,
    }),
    height1() {
      return 105.25;
    },
    width1() {
      return this.lengthMm;
    },
    offset1() {
      return 30.5;
    },
  }

}
</script>

<style scoped>
</style>
