<template>
  <div>
    <p>Ergänzen Sie bei Bedarf weitere Produkte aus unserem Portfolio zu Ihrer Anfrage:</p>
    <v-breadcrumbs :items="path" v-if="path.length > 1" class="pl-0">
      <template v-slot:divider>
        <v-icon>mdi-forward</v-icon>
      </template>
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          style="cursor: pointer"
          @click="() => (path.length -1 != item.i) ? path.splice(-(path.length - item.i - 1), path.length - item.i - 1) : ''"
          :disabled="item.disabled"
        >
          <v-btn>
            {{ item.text }}
          </v-btn>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <v-row>
      <template
        v-for="element in visibleProducts"
      >
        <v-col
          :key="element.name"
          v-if="element.type"
          style="height: 100%"
        >
          <leitplanke
            :element="element"
            v-if="element.type === 'leitplanke'"
          ></leitplanke>
          <saeulenschutz-wandschutz
            :element="element"
            v-if="element.type === 'saeulenschutz-wandschutz'"
          ></saeulenschutz-wandschutz>
          <saeulenschutz-fussgaengerschutz
            :element="element"
            v-if="element.type === 'saeulenschutz-fussgaengerschutz'"
          ></saeulenschutz-fussgaengerschutz>
          <product-select
            v-if="element.type === 'attr'"
            :attributes="element.attributes"
            :title="element.title"
            :products="element.products"
            @change="product => product ? setCurrentAdditionalProduct(product) : null">
          </product-select>
        </v-col>
        <v-col
          v-else
          sm="12"
          lg="6"
          xl="4"
          :offset-sm="0"
          :offset-lg="visibleProducts.length == 1 ? 3 : 0"
          :offset-xl="visibleProducts.length == 1 ? 4 : 0"
          :key="element.name"
        >
          <group
            v-if="element.products"
            :group="element"
            @click.native="() => path.push({text: element.name, image: element.image, disabled: false, products: element.products, i: path.length })"
          ></group>
          <product
            v-else
            :product="element" 
          ></product>
        </v-col>
      </template>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          color="primary"
          style="float: right"
          :disabled="articleAmountList.length == 0 && (!partList || partList.length == 0)"
          @click="navigateToCheckout"
        >
          Senden / Drucken
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import AdditionalProductsGroup from './additional-products/Group.vue'
import AdditionalProductsProduct from './additional-products/Product.vue'
import AdditionalProductsLeitplanke from './additional-products/Leitplanke.vue'
import AdditionalProductsSaeulenschutzFussgaenger from './additional-products/SaeulenschutzFussgaenger.vue'
import AdditionalProductsSaeulenschutzWandschutz from './additional-products/SaeulenschutzWandschutz.vue'
import AdditionalProductsProductSelect from './additional-products/ProductSelect.vue'
import ProductSelect from './additional-products/ProductSelect.vue'

export default {
  name: 'AdditionalProducts',
  components: {
    'group': AdditionalProductsGroup,
    'product': AdditionalProductsProduct,
    'product-select': AdditionalProductsProductSelect,
    'leitplanke': AdditionalProductsLeitplanke,
    'saeulenschutz-wandschutz': AdditionalProductsSaeulenschutzWandschutz,
    'saeulenschutz-fussgaengerschutz': AdditionalProductsSaeulenschutzFussgaenger,
    ProductSelect,
  },
  props: {
  },
  computed: {
    ...mapGetters({
      isProductConfigured: 'isProductConfigured',
      sides: 'computedSides',
      partList: 'partListForSides',
    }),
    ...mapState({
    }),
    visibleProducts() {
      return (this.path.length > 0) ? this.path[this.path.length - 1].products : this.products
    },
    articleList() {
      let flatGroup = (element) => {
        if(!element.products) {
          // product
          if(element.article_number && element.amount) {
            return Array(element.amount).fill(element.article_number);
          }
          if(element.configurations) {
            return element.configurations.flatMap(configuration => {
              return configuration.parts.map(part => part.article_number);
            });
          }
        }else{
          // group
          return element.products.flatMap(flatGroup);
        }
      }
      return this.products.flatMap(flatGroup).filter(v => v !== undefined);
    },
    articleAmountList() {
      let flatGroup = (element) => {
        if(element.article_number && element.amount) {
          // product
          return {
            name: element.name,
            amount: element.amount,
            article_number: element.article_number,
            removeHandler: () => element.amount = 0,
          };
        }else if(element.configurations && element.configurations.length > 0) {
          // configurable product
          return element.configurations.map((config, i) => {
            return {
              name: config.name,
              amount: 1,
              article_number: -1,
              removeHandler: () => element.configurations.splice(i, 1),
            }
          });
        }else if(element.products) {
          // group
          return element.products.flatMap(flatGroup);
        }
      }
      return this.products.flatMap(flatGroup).filter(element => element);
    },
    articleCount() {
      let flatGroup = (element) => {
        if(element.article_number && element.amount) {
          return element.amount;
        }
        if(element.configurations) {
          return element.configurations.length;
        }
        if(element.products) {
          return element.products.flatMap(flatGroup);
        }
        return 0;
      }
      return this.products.flatMap(flatGroup).reduce((prev, curr) => prev + curr, 0);
    }
  },
  data () {
    return {
      path: [],
      publicPath: process.env.BASE_URL,
      products: [
        {
          name: 'Leitplanke',
          image: 'images/products/additional/leitplanke.jpeg',
          products: [
            {
              type: 'leitplanke',
              configurations: [], // {name: '', parts: []}
            }
          ]
        },

        {
          name: 'Barriere',
          image: 'images/products/additional/22.jpg',
          products: [
            {
              type: 'attr',
              title: 'Barriere',
              attributes: {
                length: 'Länge',
              },
              products: [
                {
                  article_number: 2222,
                  name: 'Barriere Leitplanke 2900mm',
                  image: 'images/products/additional/22.jpg',
                  amount: 0,
                  attributes: {
                    length: "2900mm",
                  },
                },
                {
                  article_number: 2223,
                  name: 'Barriere Leitplanke 1450mm',
                  image: 'images/products/additional/22.jpg',
                  amount: 0,
                  attributes: {
                    length: "1450mm",
                  },
                },
              ]
            }
          ]
        },
        {
          name: 'Mittelkonsole',
          image: 'images/products/additional/mittelkonsole.jpg',
          products: [
            {
              type: 'attr',
              title: 'Mittelkonsole',
              attributes: {
                boden: 'Boden',
                height: 'Höhe',
              },
              products: [
                {
                  article_number: 346,
                  name: 'Wandschutz Mittelkonsole H 265 Komplett',
                  image: 'images/products/additional/mittelkonsole.jpg',
                  amount: 0,
                  attributes: {
                    height: 'H 265',
                    boden: 'Normal',
                  },
                },
                {
                  article_number: 347,
                  name: 'Wandschutz Mittelkonsole H 495 Komplett',
                  image: 'images/products/additional/mittelkonsole_doppelt.jpg',
                  amount: 0,
                  attributes: {
                    height: 'H 495',
                    boden: 'Normal',
                  },
                },
                {
                  article_number: 350,
                  name: 'Wandschutz Mittelkonsole H 265 FB-Heizung Komplett',
                  image: 'images/products/additional/mittelkonsole.jpg',
                  amount: 0,
                  attributes: {
                    height: 'H 265',
                    boden: 'FB-Heizung',
                  },
                },
                {
                  article_number: 351,
                  name: 'Wandschutz Mittelkonsole H 495 FB-Heizung Komplett',
                  image: 'images/products/additional/mittelkonsole_doppelt.jpg',
                  amount: 0,
                  attributes: {
                    height: 'H 495',
                    boden: 'FB-Heizung',
                  },
                },
              ]
            }
          ]
        },
       /* {
          name: 'Säulenschutz aus Wandschutz',
          image: 'images/products/additional/saeulenschutz_wandschutz.jpeg',
          products: [
            {
              type: 'saeulenschutz-wandschutz',
              configurations: [], // {name: '', parts: []}
            }
          ]
        },
        {
          name: 'Säulenschutz aus Fußgängerschutz',
          image: 'images/shapings/fussgaengerschutz-ohne-staplerbarriere-l.jpg',
          products: [
            {
              type: 'saeulenschutz-fussgaengerschutz',
              configurations: [], // {name: '', parts: []}
            }
          ]
        },*/
        {
          name: 'Eckschutz',
          image: 'images/products/additional/eckschutz.jpeg',
          products: [
            {
              type: 'attr',
              title: 'Eckschutz',
              attributes: {
                boden: 'Boden',
                color: 'Farbe',
              },
              products: [
                {
                  article_number: 32,
                  name: 'Shock Absorbing Eckschutz gelb komplett',
                  image: 'images/products/additional/eckschutz.jpeg',
                  amount: 0,
                  attributes: {
                    color: 'gelb',
                    boden: 'Normal',
                  },
                },
                {
                  article_number: 273,
                  name: 'Shock Absorbing Eckschutz gelb FB-Heizung Komplett',
                  image: 'images/products/additional/eckschutz.jpeg',
                  amount: 0,
                  attributes: {
                    color: 'gelb',
                    boden: 'FB-Heizung',
                  },
                },
                {
                  article_number: 295,
                  name: 'Shock Absorbing Eckschutz gelb/schwarz Komplett',
                  image: 'images/products/additional/eckschutz_gs.jpg',
                  amount: 0,
                  attributes: {
                    color: 'gelb/schwarz',
                    boden: 'Normal',
                  },
                },
                {
                  article_number: 296,
                  name: 'Shock Absorbing Eckschutz gelb/schwarz FB-Heizung Komplett',
                  image: 'images/products/additional/eckschutz_gs.jpg',
                  amount: 0,
                  attributes: {
                    color: 'gelb/schwarz',
                    boden: 'FB-Heizung',
                  },
                },
              ]
            }
          ]
        },
        {
          name: 'Stützenschutz',
          image: 'images/products/additional/stuetzenschutz.jpeg',
          products: [
            {
              type: 'attr',
              title: 'Stützenschutz',
              attributes: {
                boden: 'Boden',
                color: 'Farbe',
              },
              products: [
                {
                  article_number: 509,
                  name: 'Regalstützenschutz 100/120 400mm Komplett',
                  image: 'images/products/additional/stuetzenschutz.jpeg',
                  amount: 0,
                  attributes: {
                    color: 'gelb',
                    boden: 'Normal',
                  },
                },
                {
                  article_number: 812,
                  name: 'Regalstützenschutz 100/120 400mm FB-Heizung Komplett',
                  image: 'images/products/additional/stuetzenschutz.jpeg',
                  amount: 0,
                  attributes: {
                    color: 'gelb',
                    boden: 'FB-Heizung',
                  },
                },
                {
                  article_number: 1235,
                  name: 'Regalstützenschutz 100/120 400mm gelb/schwarz Komplett',
                  image: 'images/products/additional/stuetzenschutz_gs.jpg',
                  amount: 0,
                  attributes: {
                    color: 'gelb/schwarz',
                    boden: 'Normal',
                  },
                },
                {
                  article_number: 1312,
                  name: 'Regalstützenschutz 100/120 400mm gelb/schwarz FB-Heizung Komplett',
                  image: 'images/products/additional/stuetzenschutz_gs.jpg',
                  amount: 0,
                  attributes: {
                    color: 'gelb/schwarz',
                    boden: 'FB-Heizung',
                  },
                }
              ]
            }
          ]
        },
        {
          name: 'Poller',
          image: 'images/products/additional/poller.jpg',
          products: [
            {
              type: 'attr',
              title: 'Poller',
              attributes: {
                boden: 'Boden',
                /*size: 'Maße',*/
              },
              products: [
                {
                  article_number: 2050,
                  name: 'XL Flex-Poly Poller komplett schwarz - gelb, H = 600 - 1500 mm, D = 200 mm',
                  image: 'images/products/additional/poller.jpg',
                  amount: 0,
                  attributes: {
                    size: 'D=200mm, Höhe 1500mm',
                    color: 'schwarz-gelb',
                    boden: 'Normal'
                  },
                },
                {
                  article_number: 2051,
                  name: 'XL Flex-Poly Bollard FB-Heizung komplett schwarz - gelb, H = 600 - 1500 mm, D = 200 mm',
                  image: 'images/products/additional/poller.jpg',
                  amount: 0,
                  attributes: {
                    size: 'D=200mm, Höhe 1500mm',
                    color: 'schwarz-gelb',
                    boden: 'FB-Heizung',
                  },
                },
              ]
            },
          ]
        },
        {
          name: 'Barrel',
          image: 'images/products/additional/barrel.jpeg',
          products: [
            {
              article_number: 462,
              name: 'Barrel Column Guard HT-BG-YEL-BM',
              image: 'images/products/additional/barrel.jpeg',
              amount: 0,
            },
            {
              article_number: 448,
              name: 'HT Ratschenzurrgurt einteilig Standartratsche 4m',
              image: 'images/products/additional/448.jpg',
              amount: 0,
            },
            {
              article_number: 339,
              name: 'Barrel Erweiterungsadapter Säulenschutz',
              image: 'images/products/additional/339.jpg',
              amount: 0,
            },
          ],
        },
        {
          name: 'Säulenschutz',
          image: 'images/products/additional/shock_absorbing_saeulenschutz2.jpeg',
          products: [
            {
              type: 'attr',
              title: 'Säulenschutz',
              attributes: {
                boden: 'Boden',
                /*size: 'Säulenmaße',*/
              },
              products: [
                /*{
                  article_number: 1186,
                  name: 'Shock Absorbing Säulenschutz Standard Komplett für Säule 200mm x 200mm',
                  image: 'images/products/additional/shock_absorbing_saeulenschutz2.jpeg',
                  amount: 0,
                  attributes: {
                    size: '200mm x 200mm',
                    boden: 'Normal',
                  },
                },*/
                {
                  article_number: 1202,
                  name: 'Shock Absorbing Säulenschutz Standard Komplett für Säule 300mm x 300mm',
                  image: 'images/products/additional/shock_absorbing_saeulenschutz2.jpeg',
                  amount: 0,
                  attributes: {
                    size: '300mm x 300mm',
                    boden: 'Normal',
                  },
                },
                /*{
                  article_number: 1366,
                  name: 'Shock Absorbing Säulenschutz FB-Heizung Komplett für Säule 200mm x 200mm',
                  image: 'images/products/additional/shock_absorbing_saeulenschutz2.jpeg',
                  amount: 0,
                  attributes: {
                    size: '200mm x 200mm',
                    boden: 'FB-Heizung',
                  },
                },*/
                {
                  article_number: 1367,
                  name: 'Shock Absorbing Säulenschutz FB-Heizung Komplett für Säule 300mm x 300mm',
                  image: 'images/products/additional/shock_absorbing_saeulenschutz2.jpeg',
                  amount: 0,
                  attributes: {
                    size: '300mm x 300mm',
                    boden: 'FB-Heizung',
                  },
                },
              ]
            },
          ],
        },
        {
          name: 'Cheapy',
          image: 'images/products/additional/cheapy.jpeg',
          products: [
            {
              name: 'Cheapy Anfahrwinkel',
              image: 'images/products/additional/cheapy_anfahrwinkel.jpeg',
              products: [
                {
                  type: 'attr',
                  title: 'Cheapy Anfahrwinkel',
                  attributes: {
                    boden: 'Boden',
                  },
                  products: [
                    {
                      article_number: 1229,
                      name: 'Cheapy Anfahrwinkel komplett inkl. Befestigungsmaterial',
                      image: 'images/products/additional/cheapy_anfahrwinkel.jpeg',
                      amount: 0,
                      attributes: {
                        boden: 'Normal',
                      },
                    },
                    {
                      article_number: 1371,
                      name: 'Cheapy Anfahrwinkel FB-Heizung komplett inkl. Befestigungsmaterial',
                      image: 'images/products/additional/cheapy_anfahrwinkel.jpeg',
                      amount: 0,
                      attributes: {
                        boden: 'FB-Heizung',
                      },
                    },
                  ],
                }
              ]
            },
            {
              name: 'Cheapy Geländer',
              image: 'images/products/additional/cheapy_gelaender.jpg',
              products: [
                {
                  type: 'attr',
                  title: 'Cheapy Geländer',
                  attributes: {
                    size: 'Größe',
                  },
                  products: [
                    {
                      article_number: 1272,
                      name: 'Cheapy Geländer 1050x1000 komplett',
                      image: 'images/products/additional/cheapy_gelaender_1000.jpg',
                      amount: 0,
                      attributes: {
                        size: '1050x1000',
                      },
                    },
                    {
                      article_number: 1273,
                      name: 'Cheapy Geländer 1050x1500 komplett',
                      image: 'images/products/additional/cheapy_gelaender_1500.jpg',
                      amount: 0,
                      attributes: {
                        size: '1050x1500',
                      },
                    },
                    {
                      article_number: 1274,
                      name: 'Cheapy Geländer 1050x2000 komplett',
                      image: 'images/products/additional/cheapy_gelaender_2000.jpg',
                      amount: 0,
                      attributes: {
                        size: '1050x2000',
                      },
                    },
                  ]
                }
              ]
            },
            {
              name: 'Cheapy Regalstützenschutz',
              image: 'images/products/additional/cheapy_stuetze.jpeg',
              products: [
                {
                  article_number: 1276,
                  name: 'Cheapy Regalstützenschutz 100/120 370mm Komplett',
                  image: 'images/products/additional/cheapy_stuetze.jpeg',
                  amount: 0,
                },
              ],
            },
            {
              name: 'Cheapy Metall Poller',
              image: 'images/products/additional/cheapy_poller_1200.jpg',
              products: [
                {
                  type: 'attr',
                  title: 'Cheapy Metall Poller',
                  attributes: {
                    size: 'Größe',
                  },
                  products: [
                    {
                      article_number: 1278,
                      name: 'Cheapy Metall Poller Yellow 1200/168 Komplett',
                      image: 'images/products/additional/cheapy_poller_1200.jpg',
                      amount: 0,
                      attributes: {
                        size: '1200/168',
                      },
                    },
                    {
                      article_number: 1280,
                      name: 'Cheapy Metall Poller Yellow 500/168 Komplett',
                      image: 'images/products/additional/cheapy_poller_500.jpg',
                      amount: 0,
                      attributes: {
                        size: '500/168',
                      },
                    },
                  ]
                }
              ],
            }
          ],
        }
      ]
    }
  },

  mounted() {
    this.path.push({text: 'Weitere Produkte', disabled: false, products: this.products, i: 0})
  },
  methods: {
    ...mapMutations([
      'setGuideStep',
      'setGuideTab',
      'setAdditionalArticleList',
      'setAdditionalArticleAmountList',
      'setAdditionalArticleCount',
      'setCurrentAdditionalProduct',
    ]),
    navigateToCheckout() {
      this.setGuideTab(2);
    },
  },
  watch: {
    articleList(value) {
      this.setAdditionalArticleList(value);
    },
    articleAmountList(value) {
      this.setAdditionalArticleAmountList(value);
    },
    articleCount(value) {
      this.setAdditionalArticleCount(value);
    },
    path(value) {
      if(value.length) {
        this.setCurrentAdditionalProduct(value[value.length-1]);
      }else{
        this.setCurrentAdditionalProduct(null);
      }
    }
  }
}
</script>

<style scoped>
  .amount{
    width: 120px;
  }
  .v-card__text, .v-card__title {
    word-break: normal !important;
  }
</style>
